import { CSSProperties } from "react";
import styled from "@emotion/styled";
import { colours, fonts, sizes } from "constants/type";
import { FontSize, FontWeight } from "../../types";

export type Props = {
  clamp?: number;
  size: FontSize;
  subdued?: boolean;
  weight: FontWeight;
  style?: CSSProperties;
};

export const Text = styled.div(({ clamp, size, subdued, weight }: Props) => ({
  color: colours.white,
  fontFamily: fonts[weight],
  fontSize: typeof size === "string" ? sizes[size] : size,
  lineHeight: 1.22,
  opacity: subdued ? "50%" : "100%",
  ...(clamp && {
    display: "-webkit-box",
    WebkitLineClamp: clamp,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  }),
}));
