import {
  fromEvent,
  Observable,
  race,
  timer,
  merge,
  NEVER,
  Subscription,
} from "rxjs";
import {
  throttle,
  mergeMap,
  map,
  mapTo,
  takeUntil,
  delay,
} from "rxjs/operators";

export type Button =
  | "LEFT"
  | "UP"
  | "RIGHT"
  | "DOWN"
  | "SELECT"
  | "BACK"
  | "HOME"
  | "ADD"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "VOLUME_UP"
  | "VOLUME_DOWN"
  | "SETTINGS";

const buttonLookup: { [key: string]: Button } = {
  ArrowLeft: "LEFT",
  ArrowUp: "UP",
  ArrowRight: "RIGHT",
  ArrowDown: "DOWN",
  Enter: "SELECT",
  KeyB: "BACK",
  KeyH: "HOME",
  KeyA: "ADD",
  KeyU: "VOLUME_UP",
  KeyD: "VOLUME_DOWN",
  KeyS: "SETTINGS",
  Digit1: "1",
  Digit2: "2",
  Digit3: "3",
  Digit4: "4",
  Digit5: "5",
  Digit6: "6",
  Digit7: "7",
  Digit8: "8",
  Digit9: "9",
};

export const buttonPressTypes = ["LONG", "SHORT"] as const;
export type ButtonPressType = typeof buttonPressTypes[number];
type ButtonPress = {
  button: Button;
  pressType: ButtonPressType;
};

export type Tier = {
  after: number;
  every: number;
  pressType: ButtonPressType;
};

const tierStreams = (tiers: Tier[]): Observable<ButtonPressType>[] => {
  const streams = [];
  [...tiers].reverse().forEach(({ after, every, pressType }, i) => {
    const nextTierStream = streams[0] || NEVER;
    const tierStream = timer(after * 1000, every * 1000).pipe(
      mapTo(pressType), // emit "LONG" or "SHORT"
      takeUntil(nextTierStream) // take until the next tier kicks in
    );
    streams.unshift(tierStream);
  });
  return streams;
};

const calculateStream = (
  keyDown$: Observable<KeyboardEvent>,
  keyUp$: Observable<KeyboardEvent>,
  tiers: Tier[]
) => {
  const firstDown$ = keyDown$.pipe(throttle(() => keyUp$));

  return firstDown$.pipe(
    mergeMap(e =>
      race(
        keyUp$.pipe(
          mapTo({
            button: buttonLookup[e.code],
            pressType: "SHORT",
          } as ButtonPress)
        ),
        merge(...tierStreams(tiers)).pipe(
          map(pressType => ({
            button: buttonLookup[e.code],
            pressType,
          }))
        )
      ).pipe(takeUntil(keyUp$.pipe(delay(1))))
    )
  );
};

export class KeyListener {
  private keyDown$: Observable<KeyboardEvent>;
  private keyUp$: Observable<KeyboardEvent>;
  private subscription?: Subscription;

  public stream$: Observable<ButtonPress>;

  constructor({
    keyDown$,
    keyUp$,
    tiers,
  }: {
    keyDown$?: Observable<KeyboardEvent>;
    keyUp$?: Observable<KeyboardEvent>;
    tiers?: Tier[];
  } = {}) {
    this.keyDown$ =
      keyDown$ || (fromEvent(document, "keydown") as Observable<KeyboardEvent>);
    this.keyUp$ =
      keyUp$ || (fromEvent(document, "keyup") as Observable<KeyboardEvent>);
    this.updateTiers(tiers || [{ after: 0, every: 0.5, pressType: "SHORT" }]);
  }

  updateTiers(tiers: Tier[]) {
    this.stream$ = calculateStream(this.keyDown$, this.keyUp$, tiers);
  }

  subscribe(callback: (buttonPress: ButtonPress) => void) {
    this.unsubscribe();
    this.subscription = this.stream$.subscribe(callback);
  }

  unsubscribe() {
    this.subscription?.unsubscribe();
  }
}
