import { State } from "reducers";
import { FocusArea } from "reducers/browse/pages/tvGuide";
import { Hero } from "types";
import { focusArea, railHero, pageHero } from "selectors/pages/tvGuide";
import { pageTitleForHero } from "selectors/browse";

export const heroSelector = (state: State): Hero => {
  const hero = heroWithoutPageTitle(state),
    title = pageTitleForHero(state);
  return title ? { ...hero, mode: "hugeTitle", title } : hero;
};

const heroWithoutPageTitle = (state: State): Hero => {
  const area: FocusArea = focusArea(state);
  switch (area) {
    case "rail":
      return railHero(state);
    case "channels":
    case "grid":
      return pageHero(state);
    default:
      return { mode: "none" };
  }
};
