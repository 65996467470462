import React, { ReactElement, useRef, CSSProperties } from "react";
import { KeepPrevious } from "components/KeepPrevious";
import gsap from "gsap";

type Props<ChildProps> = {
  visible?: boolean;
  keyBy?: string;
  props?: ChildProps;
  render: (childProps: ChildProps) => ReactElement;
  duration?: number;
  ease?: string | gsap.EaseFunction;
  style?: CSSProperties;
};

export const Fade = <ChildProps,>({
  visible,
  keyBy,
  props,
  render,
  duration,
  ease,
  style,
}: Props<ChildProps>) => {
  if (duration === undefined) duration = 0.5;
  if (ease === undefined) ease = "none";
  if (keyBy !== undefined && visible !== undefined) {
    throw new Error(
      `Please use either 'keyBy' or 'visible' in Fade component, not both`
    );
  }

  const ref = useRef(null),
    prevRef = useRef(null);

  if (keyBy === undefined && visible === undefined) {
    return null;
  }

  return (
    <KeepPrevious
      keyBy={keyBy || String(visible)}
      props={props}
      onChange={async () => {
        if (!ref.current) return;
        await gsap.fromTo(
          ref.current,
          { opacity: 0 },
          { opacity: 1, duration, ease }
        );
      }}
      until={async () => {
        if (!prevRef.current) return;
        await gsap.to(prevRef.current, { opacity: 0, duration, ease });
      }}
      render={(props, key, isPrevious) => {
        if (
          (visible === true && isPrevious) ||
          (visible === false && !isPrevious)
        ) {
          return null;
        } else {
          return (
            <div style={style} key={key} ref={isPrevious ? prevRef : ref}>
              {render(props)}
            </div>
          );
        }
      }}
    />
  );
};
