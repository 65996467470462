import styled from "@emotion/styled";
import { grid } from "constants/dimensions";

export const Container = styled.div({
  position: "relative",
});

export const TextContainer = styled.div({
  position: "absolute",
  left: 0,
});

export const Row = styled.div({
  position: "relative",
  display: "flex",
  alignItems: "stretch",
  width: "9999em", // otherwise -webkit-mask clips at parent width
});

export const ItemContainer = styled.div<{ focused: boolean }>(
  ({ focused }) => ({
    marginRight: focused ? 0 : grid.column.gap,
  })
);
