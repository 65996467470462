import { Rail } from "types";

export default {
  type: "rail",
  uuid: "e42bc61b-cf0e-494b-8cba-2bbfb2cc900a",
  template: "tile-landscape",
  title: "Restart what's on now",
  tiles: [
    {
      type: "landscapeTile",
      title: "Music Icons",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ca96593b-f300-40be-b164-2b5ced07096f/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Music Icons",
        synopsis:
          "A fascinating look at the life and work of history's most iconic musicians and performers.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ca96593b-f300-40be-b164-2b5ced07096f/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyarts/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyarts/408/84",
    },
    {
      type: "landscapeTile",
      title: "MTV Unplugged",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/0a80018c-557d-48f7-9778-d72ae2a1406f/16-9/{width}",
      hero: {
        mode: "standard",
        title: "MTV Unplugged",
        synopsis:
          "A staple of MTV's programming lineup in the 1990s, `Unplugged' features popular artists performing acoustic -- or unplugged -- versions of some of their biggest hits, offering their fans a chance to experience favourite tunes in a new way.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/0a80018c-557d-48f7-9778-d72ae2a1406f/background/{width}",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_mtv/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_mtv/408/84",
    },
    {
      type: "landscapeTile",
      title: "Discovering Music",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/808f5032-c681-4ccc-b848-ec964850eb2f/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Discovering Music",
        synopsis:
          "Music journalists take a closer look at the work of some of the biggest recording artists in the world, past and present.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/808f5032-c681-4ccc-b848-ec964850eb2f/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyarts/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyarts/408/84",
    },
    {
      type: "landscapeTile",
      title: "Music Videos That Defined the 2000s",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/eacc0946-b355-4f74-86ab-b452d8829f5d/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Music Videos That Defined the 2000s",
        synopsis:
          "...The 2000s. An entertaining look at the most iconic music videos of the 2000s, when the likes of Beyonce and Fatboy Slim broke new ground in music television.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/eacc0946-b355-4f74-86ab-b452d8829f5d/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyarts/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyarts/408/84",
    },
    {
      type: "landscapeTile",
      title: "Coldplay: Live in Sao Paulo",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/aa1645a8-a5a8-4c3a-bc38-9669bc39333a/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Coldplay: Live in Sao Paulo",
        synopsis:
          "This exhilarating concert film captures at the climax of the Coldplay's record-breaking A Head Full of Dreams Tour in Sao Paulo in November 2017.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/aa1645a8-a5a8-4c3a-bc38-9669bc39333a/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyarts/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyarts/408/84",
    },
  ],
} as Rail;
