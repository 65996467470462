import { Action } from "types/actions";
import { Tier } from "services/keyListener";

export const pageTitlePositions = [
  "top-left",
  "top-right",
  "hero",
  "none",
] as const;
export type PageTitlePosition = typeof pageTitlePositions[number];

export const backBehaviours = [
  "once-for-page-back",
  "twice-for-page-back",
] as const;
export type BackBehaviour = typeof backBehaviours[number];

export type SettingsState = Readonly<{
  pageTitlePosition: PageTitlePosition;
  backBehaviour: BackBehaviour;
  enableBrowseTabs: boolean;
  buttonPressTiers: Tier[];
}>;

export const initialSettingsState: SettingsState = {
  pageTitlePosition: "hero" as PageTitlePosition,
  backBehaviour: "once-for-page-back" as BackBehaviour,
  enableBrowseTabs: false,
  buttonPressTiers: [
    { after: 0.3, every: 0.3, pressType: "SHORT" },
    { after: 1.2, every: 1.2, pressType: "LONG" },
    { after: 999, every: 1, pressType: "LONG" },
  ],
};

export const settingsReducer = (
  state: SettingsState = initialSettingsState,
  action: Action
) => {
  switch (action.type) {
    case "SETTINGS.UPDATE":
      return { ...state, ...action.settings };
    default:
      return state;
  }
};
