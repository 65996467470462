import uuid from "uuid";
import { tvGuide } from "constants/dimensions";
import { format, addMinutes, differenceInMinutes, addHours } from "date-fns";
import { findLastIndex, range } from "lodash";
import {
  ChannelId,
  ChannelSchedule,
  LinearEvent,
  LinearEventLookup,
  PartialLinearEventLookup,
  Rail,
  Schedule,
} from "../types";

const SLOT_DURATION_MINS = 30;

const msToMins = (ms: number) => ms / 1000 / 60;

export const timeSlots = (slotIndex: number) => {
  const midnight = new Date(2020, 0, 1);
  return range(slotIndex - 1, slotIndex + 5).map(index => {
    const timeMs = addMinutes(midnight, index * SLOT_DURATION_MINS);
    return {
      index,
      title: format(timeMs, "h:mma").toLowerCase(),
    };
  });
};

export const timeToX = (timeMs: number, midnight: Date) =>
  Math.round(
    (msToMins(timeMs - midnight.getTime()) * tvGuide.grid.slotWidth) /
      SLOT_DURATION_MINS
  );

export const durationToWidth = (durationMs: number) =>
  Math.round(
    (msToMins(durationMs) * tvGuide.grid.slotWidth) / SLOT_DURATION_MINS
  );

export const slotNumToX = (slot: number) => slot * tvGuide.grid.slotWidth;

export const slotIndexFromDate = (date: number | Date, midnight: Date) =>
  Math.floor(differenceInMinutes(date, midnight) / SLOT_DURATION_MINS);

export const slotIndexToDate = (slotIndex: number, midnight: Date): Date =>
  addMinutes(midnight, slotIndex * SLOT_DURATION_MINS);

export const eventIndexForDate = (date: Date, events: LinearEvent[]) =>
  events.findIndex(event => event.endMs > date.getTime());

export const slotIndexForEvent = (event: LinearEvent, midnight: Date) =>
  Math.floor(differenceInMinutes(event.startMs, midnight) / SLOT_DURATION_MINS);

export const lastStartedEventIndex = (
  slotIndex: number,
  events: LinearEvent[],
  midnight: Date
) => {
  const slotTimeMs = addMinutes(
      midnight,
      slotIndex * SLOT_DURATION_MINS
    ).getTime(),
    index = findLastIndex(events, event => event.startMs <= slotTimeMs);
  return index === -1 ? undefined : index;
};

export const placeholderRail = (): Rail => ({
  type: "rail",
  uuid: uuid.v4(),
  template: "tile-landscape",
  tiles: range(0, 5).map(() => ({
    type: "landscapeTile",
  })),
});

export const placeholderChannels = (midnight: Date): ChannelSchedule[] => {
  const channelIds: ChannelId[] = [
    "placeholder-channel-1",
    "placeholder-channel-2",
    "placeholder-channel-3",
    "placeholder-channel-4",
    "placeholder-channel-5",
    "placeholder-channel-6",
  ];
  return channelIds.map(id => ({
    channel: {
      id,
      channelNumber: "",
      title: "",
    },
    events: range(0, 24).map(eventIndex => {
      const startMs = addHours(midnight, eventIndex).getTime();
      return {
        startMs,
        endMs: startMs + 3_600_000,
        programmeId: "",
        title: "",
      };
    }),
  }));
};

export const modifySchedule = (
  schedule: Schedule,
  overrides: PartialLinearEventLookup,
  dateNow: Date
) => {
  const modifiedSchedule: Schedule = { channels: [...schedule.channels] };
  const { channels } = schedule;
  for (let channelId in overrides) {
    const channelIndex = channels.findIndex(c => c.channel.id === channelId),
      channelSchedule = channelIndex > -1 ? channels[channelIndex] : undefined;
    if (channelSchedule) {
      const { events } = channelSchedule;
      const idx = findLastIndex(events, e => e.startMs <= dateNow.getTime());
      if (idx > -1) {
        modifiedSchedule.channels[channelIndex] = {
          channel: channelSchedule.channel,
          events: [...events],
        };
        modifiedSchedule.channels[channelIndex].events[idx] = {
          ...events[idx],
          ...overrides[channelId],
        };
      }
    }
  }
  return modifiedSchedule;
};

export const modifyEventLookup = (
  eventLookup: LinearEventLookup,
  overrides: PartialLinearEventLookup
) => {
  const modifiedEventLookup = { ...eventLookup };
  for (let channelId in overrides) {
    modifiedEventLookup[channelId] = {
      ...modifiedEventLookup[channelId],
      ...overrides[channelId],
    };
  }
  return modifiedEventLookup;
};
