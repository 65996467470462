import React, { useRef } from "react";
import { useSelector } from "store";
import styled from "@emotion/styled";
import { browse, screen } from "constants/dimensions";
import { RailsPage } from "components/pages/RailsPage";
import { GridPage } from "components/pages/GridPage";
import { ImagePage } from "components/pages/ImagePage";
import { TvGuidePage } from "../pages/TvGuidePage";
import { KeepPrevious } from "components/KeepPrevious";
import { PageState } from "reducers/browse";
import { useAnimations } from "hooks/useAnimations";
import gsap from "gsap";
import { transitions } from "constants/transitions";
import { boolToInt } from "utils/boolToInt";
import { focusArea, switcherIsShowing } from "selectors/app";
import { currentPage } from "selectors/currentPage";
import {
  Page,
  RailsPage as RailsPageType,
  GridPage as GridPageType,
  TvGuidePage as TvGuidePageType,
  ImagePage as ImagePageType,
} from "types";
import { now } from "selectors/time";

const Container = styled.div`
  position: relative;
`;

const PageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${screen.width}px;
  height: ${screen.height}px;
  transform-origin: center ${screen.height / 2}px;
`;

const pageComponent = (
  pageState: PageState,
  page: Page,
  focused: boolean,
  date: Date
) => {
  if (!pageState) return null;
  switch (pageState.pageType) {
    case "railsPage":
      return (
        <RailsPage
          page={page as RailsPageType}
          index={pageState.index}
          railIndices={pageState.railIndices}
          focused={focused}
        />
      );
    case "gridPage":
      return (
        <GridPage
          page={page as GridPageType}
          index={pageState.index}
          focused={focused}
        />
      );
    case "imagePage":
      return <ImagePage page={page as ImagePageType} />;
    case "tvGuidePage":
      return (
        <TvGuidePage
          schedule={pageState.schedule}
          slotIndex={pageState.slotIndex}
          focusArea={pageState.focusArea}
          page={page as TvGuidePageType}
          tileIndex={pageState.tileIndex}
          channelIndex={pageState.channelIndex}
          eventIndex={pageState.eventIndex}
          midnight={pageState.midnight}
          now={date}
          loadingAnotherDay={pageState.loadingAnotherDay}
          showRail={pageState.showRail}
        />
      );
  }
};

const browseY = (showSwitcher: boolean) =>
  showSwitcher ? browse.idle.y : browse.focused.y;

export const Browse = () => {
  const focused = useSelector(focusArea) === "browse",
    showSwitcher = useSelector(switcherIsShowing),
    { pageState, page } = useSelector(currentPage),
    date = useSelector(now),
    browseRef = useRef(null),
    pageRef = useRef(null),
    prevPageRef = useRef(null);

  useAnimations(
    {
      reset: params => gsap.set(browseRef.current, params),
      animations: {
        switcherToBrowse: (params, timeline) =>
          timeline.to(browseRef.current, {
            ...params,
            ...transitions.switcherToBrowse,
          }),
        browseToSwitcher: (params, timeline) =>
          timeline.to(browseRef.current, {
            ...params,
            ...transitions.browseToSwitcher,
          }),
      },
    },
    {
      y: browseY(showSwitcher),
    }
  );

  useAnimations({
    animations: {
      browseForward: (_, timeline) =>
        timeline
          .fromTo(
            pageRef.current,
            { scale: 0.9, opacity: 0 },
            { scale: 1, opacity: 1, duration: 1.2, ease: "power2.out" },
            0
          )
          .fromTo(
            prevPageRef.current,
            { scale: 1, opacity: 1 },
            {
              scale: 1.1,
              opacity: 0,
              duration: 0.8,
              ease: "power2.out",
            },
            0
          ),
      browseBack: (_, timeline) =>
        timeline
          .fromTo(
            pageRef.current,
            { scale: 1.1, opacity: 0 },
            { scale: 1, opacity: 1, duration: 1.2, ease: "power2.out" },
            0
          )
          .fromTo(
            prevPageRef.current,
            { scale: 1, opacity: 1 },
            {
              scale: 0.9,
              opacity: 0,
              duration: 0.8,
              ease: "power2.out",
            },
            0
          ),
      browseFade: (_, timeline) =>
        timeline
          .fromTo(
            pageRef.current,
            { opacity: 0 },
            { opacity: 1, duration: 1.2, ease: "power2.out" },
            0
          )
          .fromTo(
            prevPageRef.current,
            { opacity: 1 },
            { opacity: 0, duration: 0.8, ease: "power2.out" },
            0
          ),
      browsePageDown: (_, timeline) =>
        timeline
          .fromTo(
            prevPageRef.current,
            { y: 0, opacity: 1 },
            {
              y: -screen.height,
              opacity: 0,
              duration: 0.8,
              ease: "power2.out",
            },
            0
          )
          .fromTo(
            pageRef.current,
            { y: screen.height, opacity: 0 },
            { y: 0, opacity: 1, duration: 1.2, ease: "power2.out" },
            0
          ),
      browsePageUp: (_, timeline) =>
        timeline
          .fromTo(
            prevPageRef.current,
            { y: 0, opacity: 1 },
            { y: screen.height, opacity: 0, duration: 0.8, ease: "power2.out" },
            0
          )
          .fromTo(
            pageRef.current,
            { y: -screen.height, opacity: 0 },
            { y: 0, opacity: 1, duration: 1.2, ease: "power2.out" },
            0
          ),
    },
    reset: () => {
      gsap.set(prevPageRef.current, { scale: 1, opacity: 0 });
      gsap.set(pageRef.current, { scale: 1, opacity: 1 });
    },
  });

  return (
    <Container id="browse" ref={browseRef}>
      <KeepPrevious
        keyBy={pageState.pageId}
        duration={2}
        props={{ pageState, page, focused }}
        render={({ pageState, page, focused }, key, isPrevious) => (
          <PageWrapper
            className={key}
            key={key}
            ref={isPrevious ? prevPageRef : pageRef}
            style={{ opacity: boolToInt(!isPrevious) }}
          >
            {pageComponent(pageState, page, focused, date)}
          </PageWrapper>
        )}
      />
    </Container>
  );
};
