import React from "react";
import { useSelector, useDispatch } from "store";
import { Shell } from "components/Shell";
import { Background } from "components/Background";
import { Toast } from "components/Toast";
import { Switcher } from "components/Switcher";
import { Hero } from "components/Hero";
import { PageTitle } from "components/PageTitle";
import { Browse } from "components/Browse";
import { IntroVideo } from "components/IntroVideo";
import { heroSelector } from "selectors/hero";
import { pageTitle as pageTitleSelector } from "selectors/pageTitle";
import { VolumePanel } from "components/VolumePanel";
import { Settings } from "components/Settings";
import { BrowseTabs } from "components/BrowseTabs";
import { Clock } from "components/Clock";
import { MiniGuide } from "components/MiniGuide";

export const AppShell = () => {
  const dispatch = useDispatch(),
    state = useSelector(s => s),
    {
      fullscreenMode,
      pageBackgroundSrc,
      introVideoUrl,
      settingsOpen,
    } = state.app,
    toast = state.toast,
    tv = state.tv,
    hero = heroSelector(state),
    pageTitle = pageTitleSelector(state),
    settings = state.settings,
    topLeftPageTitle =
      settings.pageTitlePosition === "top-left" ? pageTitle?.text : undefined,
    timeMs = state.time.timeMs,
    showMiniGuide = state.miniGuide.visible;

  return (
    <>
      <Shell>
        <Background src={pageBackgroundSrc} key={pageBackgroundSrc} />
        <Hero hero={hero} hidden={fullscreenMode} />
        <Browse />
        <BrowseTabs />
        <Switcher />
        {settings.pageTitlePosition === "top-right" && (
          <PageTitle
            pageTitle={pageTitle}
            position={settings.pageTitlePosition}
          />
        )}
        <Clock visible={!fullscreenMode} timeMs={timeMs} />
        <Toast
          message={toast.message || topLeftPageTitle}
          quote={toast.quote}
          status={toast.status}
          mode={fullscreenMode ? "overlay" : "header"}
          showSeparator={!!topLeftPageTitle}
        />
        {introVideoUrl && <IntroVideo videoUrl={introVideoUrl} />}
        <VolumePanel
          volume={tv.volume}
          muted={tv.muted}
          visible={tv.volumeVisible}
        />
        <MiniGuide visible={showMiniGuide} />
        <Settings
          open={settingsOpen}
          settings={settings}
          onUpdateSettings={settings =>
            dispatch({ type: "SETTINGS.UPDATE", settings })
          }
          onClose={() => dispatch({ type: "SETTINGS.TOGGLE" })}
        />
      </Shell>
    </>
  );
};
