import { Rail } from "types";

export default {
  type: "rail",
  uuid: "8185e747-ac5a-4701-b51c-cb297db573da",
  template: "tile-square",
  title: "Channels",
  tiles: [
    {
      type: "squareTile",
      title: "BBC",
      imageUrl: "assets/images/channels/BBC.png",
      action: { type: "goToPage", pageType: "railsPage", pageId: "bbc" },
      hero: {
        mode: "standard",
        title: "BBC",
        synopsis:
          "Enjoy watching live or on demand TV, from live news coverage, music and big sport events to great comedies, gripping documentaries and nail-biting dramas.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/c60094df-e103-4de2-b9cc-32c95781220a/background/{width}",
      },
    },
    {
      type: "squareTile",
      title: "ITV Hub",
      imageUrl: "assets/images/channels/ITV_Hub.png",
      hero: {
        mode: "standard",
        title: "ITV Hub",
        synopsis:
          "Enjoy watching live or on demand TV, from live news coverage, music and big sport events to great comedies, gripping documentaries and nail-biting dramas.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/8b9451bcce9271f1e67d5ba419a03ae5/hero.jpg",
      },
    },
    {
      type: "squareTile",
      title: "All 4",
      imageUrl: "assets/images/channels/All_4.png",
      hero: {
        mode: "standard",
        title: "All 4",
        synopsis:
          "Enjoy watching live or on demand TV, from live news coverage, music and big sport events to great comedies, gripping documentaries and nail-biting dramas.",
        heroImageUrl: "assets/images/content/Whale Wisdom/hero.jpg",
      },
    },
    {
      type: "squareTile",
      title: "Channel 5",
      imageUrl: "assets/images/channels/Channel_5.png",
      hero: {
        mode: "standard",
        title: "Channel 5",
        synopsis:
          "Enjoy watching live or on demand TV, from live news coverage, music and big sport events to great comedies, gripping documentaries and nail-biting dramas.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/2b44a65694f2f48a52d758ff24413d33/hero.jpg",
      },
    },
    {
      type: "squareTile",
      title: "My 5",
      imageUrl: "assets/images/channels/My_5.png",
      hero: {
        mode: "standard",
        title: "My 5",
        synopsis:
          "Enjoy watching live or on demand TV, from live news coverage, music and big sport events to great comedies, gripping documentaries and nail-biting dramas.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/54e43bea4f59679927dba5840b41a6cf/hero.jpg",
      },
    },
    {
      type: "squareTile",
      title: "FOX",
      imageUrl: "assets/images/channels/FOX.png",
      hero: {
        mode: "standard",
        title: "FOX",
        synopsis:
          "Enjoy watching live or on demand TV, from live news coverage, music and big sport events to great comedies, gripping documentaries and nail-biting dramas.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/8432f0e56445b286ea07207f86953a23/hero.jpg",
      },
    },
    {
      type: "squareTile",
      title: "UKTV Play",
      imageUrl: "assets/images/channels/UKTV_Play.png",
      hero: {
        mode: "standard",
        title: "UKTV Play",
        synopsis:
          "Enjoy watching live or on demand TV, from live news coverage, music and big sport events to great comedies, gripping documentaries and nail-biting dramas.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/7ce49a9cdbf93f59bc6d56f9dcfb8bd3/hero.jpg",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        synopsis:
          "Enjoy watching live or on demand TV, from live news coverage, music and big sport events to great comedies, gripping documentaries and nail-biting dramas.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/7ce49a9cdbf93f59bc6d56f9dcfb8bd3/hero.jpg",
      },
    },
  ],
} as Rail;
