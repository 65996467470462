import React from "react";
import { Text } from "../Text";
import {
  Container,
  ContentContainer,
  MediaContainer,
  Image,
  Logo,
  LogoContainer,
} from "./LandscapeTile.styles";
import { LandscapeTile as LandscapeTileType } from "types";
import { landscape } from "constants/assetSizes";
import { imageUrlFromTemplate } from "utils/imageUrlFromTemplate";

type Props = {
  focused: boolean;
  tile: LandscapeTileType;
};

export const LandscapeTile = ({
  focused,
  tile: { title, providerImageUrl, imageUrl },
}: Props) => {
  const hasContent = title || providerImageUrl ? true : false;

  return (
    <Container focused={focused}>
      <MediaContainer>
        <Image alt="" src={imageUrlFromTemplate(imageUrl, landscape)} />
      </MediaContainer>
      {hasContent && (
        <ContentContainer>
          <LogoContainer>
            <Logo src={providerImageUrl} alt="" />
          </LogoContainer>
          <Text size="medium" clamp={1} weight={focused ? "medium" : "regular"}>
            {title}
          </Text>
        </ContentContainer>
      )}
    </Container>
  );
};
