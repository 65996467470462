import { Rail } from "types";

export default {
  type: "rail",
  uuid: "062e115d-1a6f-4f9b-ac94-d785d67dbe54",
  template: "tile-landscape",
  title: "Unmissable TV Series",
  tiles: [
    {
      type: "landscapeTile",
      title: "Sherlock",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/de55da47-8770-4535-abeb-cc6f55165168/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Sherlock",
        metadata: "1h 45m   ",
        synopsis:
          "A Study in Pink. Dr Watson and Sherlock Holmes meet, and tackle the case of the Impossible Suicides.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/de55da47-8770-4535-abeb-cc6f55165168/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "Spooks",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/af4a1c22-e30d-47f9-b409-6cbd226b4b6d/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Spooks",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 1. A car bomb explodes in Liverpool and Zoe is sent undercover to investigate.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/af4a1c22-e30d-47f9-b409-6cbd226b4b6d/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "In the Long Run",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/a57adb8c868da2892eb98fad83c25797/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
      hero: {
        mode: "standard",
        title: "In the Long Run",
        synopsis:
          "Head back to the tower blocks and shell suits of 1980s East London for a second season of the charming Idris Elba comedy. A Sky original also starring Bill Bailey. All episodes available.",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/067ab838d59502dde21bcc8b3ba2d445/herotitle.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/dbad581eb0a642d6ea5f77bcf1b2ca16/hero.jpg",
        metadata: "1h 45m   ",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
      },
    },
    {
      type: "landscapeTile",
      title: "Monster Family",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/2df6c70c356c06ac8372251ca9750986/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      hero: {
        mode: "standard",
        title: "Monster Family",
        synopsis:
          "The first Sky Cinema Original Film sees a young family cursed by a witch and transformed into monsters. Animated comedy with the voices of Emily Watson and Jason Isaacs. (2017)(90 mins)",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/9de9d887c96318e371e293620734c05e/hero.jpg",
        metadata: "1h 45m   ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      },
    },
    {
      type: "landscapeTile",
      title: "What We Do in the Shadows",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/2f30c794-7dff-4d98-b461-85c318b6aca5/16-9/{width}",
      hero: {
        mode: "standard",
        title: "What We Do in the Shadows",
        metadata: "1h 45m   ",
        synopsis:
          "Resurrection. The vampires enlist the services of a local necromancer. Contains some strong language and some violence.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/2f30c794-7dff-4d98-b461-85c318b6aca5/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
    },
    {
      type: "landscapeTile",
      title: "Glow Up: Britain's Next Make-Up...",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/71da94d8-2713-4564-ab28-ce0b12dd4e0e/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Glow Up: Britain's Next Make-Up...",
        metadata: "1h 45m   ",
        synopsis:
          "...Star. Episode 1. The MUAs work on an advertising campaign for sports fashion retailer JD.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/71da94d8-2713-4564-ab28-ce0b12dd4e0e/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "Storyville",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/a5900032-4c78-44b8-aeb7-5e0c33a23423/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Storyville",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 1. The inspiring story of men and women in the USA pursuing college degrees while in prison.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_bbcfour/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/a5900032-4c78-44b8-aeb7-5e0c33a23423/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcfour/408/84",
    },
    {
      type: "landscapeTile",
      title: "Inside Monaco: Playground of the...",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/4bd7a3cd-2c6a-4209-8e1d-83bac8a24e1a/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Inside Monaco: Playground of the...",
        metadata: "1h 45m   ",
        synopsis:
          "...Rich. Episode 2. The Casino de Monte Carlo holds an exclusive event for high-rollers.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/4bd7a3cd-2c6a-4209-8e1d-83bac8a24e1a/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
    },
  ],
} as Rail;
