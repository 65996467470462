import { State } from "reducers";
import { currentPage } from "selectors/currentPage";

export const pageType = (state: State) => state.browse.pageState.pageType;

export const onHomepage = ({ browse: { pageState } }: State) =>
  pageState.pageId === "home";

export const pageTitleForHero = (state: State): string | undefined => {
  if (state.browse.showPageTitleInHero) {
    const { page } = currentPage(state);
    return "title" in page ? page.title.text : undefined;
  }
};
