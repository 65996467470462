import { journeyToMyListItem } from "data/myList";
import { put, select, takeEvery } from "sagas/effects";
import { currentJourneyId } from "selectors/journey";

export function* myListSaga() {
  yield takeEvery("MY_LIST.ADD_CURRENT", onAddCurrent);
}

function* onAddCurrent() {
  const journeyId = yield select(currentJourneyId),
    item = journeyToMyListItem(journeyId);
  if (item) {
    yield put({ type: "MY_LIST.ADD", item });
    yield put({ type: "MINIGUIDE.SHOW" });
  }
}
