import { State } from "reducers";
import { heroSelector as switcherHeroSelector } from "selectors/hero/switcher";
import { heroSelector as browseTabsHeroSelector } from "selectors/hero/browseTabs";
import { heroSelector as railsHeroSelector } from "selectors/hero/pages/railsPage";
import { heroSelector as gridHeroSelector } from "selectors/hero/pages/gridPage";
import { heroSelector as imageHeroSelector } from "selectors/hero/pages/imagePage";
import { heroSelector as tvGuideHeroSelector } from "selectors/hero/pages/tvGuidePage";
import { Hero } from "types";
import { focusArea, switcherIsShowing } from "selectors/app";
import { pageType } from "selectors/browse";

export const heroSelector = (state: State): Hero => {
  switch (focusArea(state)) {
    case "browse":
      return browsePageHeroSelector(state);
    case "browseTabs":
      return switcherIsShowing(state)
        ? switcherHeroSelector(state)
        : browseTabsHeroSelector(state);
    case "switcher":
      return switcherHeroSelector(state);
  }
};

export const browsePageHeroSelector = (state: State): Hero => {
  switch (pageType(state)) {
    case "railsPage":
      return railsHeroSelector(state);
    case "gridPage":
      return gridHeroSelector(state);
    case "imagePage":
      return imageHeroSelector(state);
    case "tvGuidePage":
      return tvGuideHeroSelector(state);
    default:
      return { mode: "none" };
  }
};
