import { Rail } from "types";

export default {
  type: "rail",
  uuid: "33298ae9-a30c-4d72-9de2-61798458b044",
  template: "tile-cover",
  title: "Top Movie Picks",
  tiles: [
    {
      type: "coverTile",
      title: "Charlie's Angels",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/d4a668c8-5719-4d0b-9f01-7cbb19f8a123/cover/{height}",
      hero: {
        mode: "standard",
        title: "Charlie's Angels",
        synopsis:
          "A new generation of angels are called back into action for a dangerous assignment. Third film in the series starring Kristen Stewart, Naomi Scott and Ella Balinska. (2019)(119 mins)",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/d4a668c8-5719-4d0b-9f01-7cbb19f8a123/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
    },
    {
      type: "coverTile",
      title: "Good Boys",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/52e81425-d41f-4a35-afd7-65cb58edc600/cover/{height}",
      hero: {
        mode: "standard",
        title: "Good Boys",
        synopsis:
          "When 12-year-old Max is invited to his first kissing party, there's a problem...he doesn't know how - and his quest to learn gets him into big trouble. (2019)(89 mins)",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/52e81425-d41f-4a35-afd7-65cb58edc600/background/{width}",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_all4/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_all4/408/84",
    },
    {
      type: "coverTile",
      title: "Jumanji: Welcome to the Jungle",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/5fbeeb3b-d30e-4692-a0a5-5a9a9c35dadb/cover/{height}",
      hero: {
        mode: "standard",
        title: "Jumanji: Welcome to the Jungle",
        synopsis:
          "An old video game throws four teens into the world of Jumanji. Dwayne Johnson stars. Strong language/violence/flashing images. (2017)(115 mins)",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/5fbeeb3b-d30e-4692-a0a5-5a9a9c35dadb/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
    },
    {
      type: "coverTile",
      title: "Olympic Dreams",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/db6e3430-19af-4c4a-a971-58208bc6e441/cover/{height}",
      hero: {
        mode: "standard",
        title: "Olympic Dreams",
        synopsis:
          "A tender and unexpected love story unfolds between a young cross-country skier and a volunteer doctor in the Olympic athletes village. (2020)(82 mins)",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/db6e3430-19af-4c4a-a971-58208bc6e441/background/{width}",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
    },
    {
      type: "coverTile",
      title: "Sprinter",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/03b99579-3c76-4f64-9769-357c07861e17/cover/{height}",
      hero: {
        mode: "standard",
        title: "Sprinter",
        synopsis:
          "A young boy embarks on a journey to overcome his troubled family life and become Jamaica's next track-and-field sensation, in the hopes of being reunited with his mother. (2019)(112 mins)",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/03b99579-3c76-4f64-9769-357c07861e17/background/{width}",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_itv/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_itv/408/84",
    },
    {
      type: "coverTile",
      title: "Fast & Furious Presents: Hobbs & Shaw",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/7cd0fcec-0f5a-4a8a-963d-4bd862f758f5/cover/{height}",
      hero: {
        mode: "standard",
        title: "Fast & Furious Presents: Hobbs & Shaw",
        synopsis:
          "Spin-off from the iconic franchise. Luke Hobbs (Dwayne Johnson) and Deckard Shaw (Jason Statham) must put their differences aside and team up to stop a huge terror threat. (2019)(137 mins)",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/7cd0fcec-0f5a-4a8a-963d-4bd862f758f5/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
    },
    {
      type: "coverTile",
      title: "Always Be My Maybe",
      imageUrl: "./assets/images/content/always-be/always-be-my-maybe.jpg",
      action: {
        type: "startJourney",
        journeyId: "netflix",
        pageIndex: 1,
      },
      hero: {
        mode: "standard",
        title: "Always Be My Maybe",
        synopsis:
          "Childhood sweethearts have a falling out and don't speak for 15 years, only reconnecting as adults when Sasha runs into Marcus in San Francisco. Although the old sparks are still there, the couple live in different worlds.",
        heroTitleImageUrl:
          "./assets/images/content/always-be/always-be-my-maybe-title.png",
        heroImageUrl: "./assets/images/content/always-be/awmm-cover.jpeg",
        logoUrl:
          "https://cdn.skyq.sky.com/uk/epg/live/media/logos/netflix/v1/logo_365x75.png",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "The Greatest Showman",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/6c19ce32-fea1-47f1-b057-1ff4cb208439/cover/528",
      action: {
        type: "startJourney",
        journeyId: "greatestShowman",
        pageIndex: 1,
      },
      hero: {
        mode: "standard",
        title: "The Greatest Showman",
        synopsis:
          "Showstopping musical biopic starring Hugh Jackman as legendary 19th-century circus entrepreneur PT Barnum. With Zac Efron and Michelle Williams. Contains flashing images. (2017)(105 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/6c19ce32-fea1-47f1-b057-1ff4cb208439/background/1428",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Out Of Blue",
      imageUrl: "./assets/images/content/out-of-blue/out-of-blue-tile.jpg",
      action: {
        type: "goToPage",
        pageType: "imagePage",
        pageId: "outOfBlue",
      },
      hero: {
        mode: "standard",
        title: "Out Of Blue",
        synopsis:
          "Mike Hoolihan is an unconventional New Orleans cop investigating the murder of renowned astrophysicist Jennifer Rockwell, a black hole expert found shot to death in her observatory. As Mike tumbles down the rabbit hole of the disturbing case, she finds herself grappling with increasingly existential questions of quantum mechanics, parallel universes, and exploding stars - cosmic secrets that may hold the key to unraveling the crime, while throwing into doubt her very understanding of reality.",
        heroImageUrl:
          "./assets/images/content/out-of-blue/Out-of-blue-hero.jpg",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Maleficent",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/a2bf3e43-6eee-4dbb-aa8c-ed82b15fd7ed/cover/{height}",
      hero: {
        mode: "standard",
        title: "Maleficent",
        synopsis:
          "Fantasy adventure reimagining of the classic Sleeping Beauty fairy tale. In an act of revenge, a dark fairy places a curse on the king's daughter, with dramatic consequences.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/a2bf3e43-6eee-4dbb-aa8c-ed82b15fd7ed/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Last Vegas",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/29df240e-7459-4ead-977d-098d3bfa12ae/cover/{height}",
      hero: {
        mode: "standard",
        title: "Last Vegas",
        synopsis:
          "Old pals Robert De Niro, Kevin Kline and Morgan Freeman travel to Las Vegas for friend Michael Douglas's bachelor party. Fun comedy. Flashing images/sex/strong language. (2013)(101 mins)   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/29df240e-7459-4ead-977d-098d3bfa12ae/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "An Officer and a Gentleman",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/51d939c1-dc9a-4e85-85fa-660cf91f6b48/cover/{height}",
      hero: {
        mode: "standard",
        title: "An Officer and a Gentleman",
        synopsis:
          "Classic romantic drama starring Richard Gere as a troubled trainee naval officer who falls for Debra Winger's sassy factory girl. Strong language/moderate sex and nudity. (1982)(122 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/51d939c1-dc9a-4e85-85fa-660cf91f6b48/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Lone Survivor",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ed7e2d89-d0b9-4163-9008-eeeec9bc58dc/cover/{height}",
      hero: {
        mode: "standard",
        title: "Lone Survivor",
        synopsis:
          "Mark Wahlberg and Taylor Kitsch star in this compelling war film depicting a 2005 Navy SEALs op in Afghanistan. Strong language/violence/flashing images. (2014)(117 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ed7e2d89-d0b9-4163-9008-eeeec9bc58dc/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Hotel Mumbai",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ccc6c731-eac0-4024-953e-e203036f4101/cover/{height}",
      hero: {
        mode: "standard",
        title: "Hotel Mumbai",
        synopsis:
          "True story of the 2008 Taj Hotel terror attacks and the hotel staff who risked it all to protect their guests. With Dev Patel. Strong language/violence/sex/mature themes. (2018)(123 mins) ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ccc6c731-eac0-4024-953e-e203036f4101/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "The Top Ten Show",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/69ce737b-8b8a-4f3b-a9f1-2a5a7e8a632d/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Top Ten Show",
        synopsis:
          "Which movies are doing the best business at home and across the pond? Clips from the UK and US Top Ten show you the best of what's on offer on the big screen.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/69ce737b-8b8a-4f3b-a9f1-2a5a7e8a632d/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Pet Sematary",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/fa75e9af-e0c4-49f8-bd73-42228e43518c/cover/{height}",
      hero: {
        mode: "standard",
        title: "Pet Sematary",
        synopsis:
          "When a young boy dies in an accident, his father buries him in a cemetery with the power to resurrect its inhabitants. Stephen King adaptation. Strong language/violence. (1989)(98 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/fa75e9af-e0c4-49f8-bd73-42228e43518c/background/1428",
        metadata: "1h 45m   ",
      },
    },
  ],
} as Rail;
