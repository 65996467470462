import { ImagePage } from "types";

export default {
  type: "imagePage",
  url: "assets/images/showPages/out-of-blue-showpage.jpg",
  action: {
    type: "startJourney",
    journeyId: "outOfBlue",
  },
} as ImagePage;
