import { NavStep } from "types";
import { select, put, animate } from "sagas/effects";
import {
  selectedTile,
  selectedRailIndex,
  onFirstRail,
  onFirstRailAndTile,
  prevRailIndex,
  nextRailIndex,
  prevTileIndex,
  nextTileIndex,
} from "selectors/pages/rails";
import { userActionToReduxAction } from "utils/userActionToReduxAction";
import { SettingsState } from "reducers/settings";
import { onHomepage } from "selectors/browse";
import { showBrowseTabs } from "selectors/browseTabs";

export function* railsPageNavigate(step: NavStep) {
  const settings: SettingsState = yield select(s => s.settings),
    railIndex = yield select(selectedRailIndex);
  switch (step) {
    case "UP":
      if (yield select(onFirstRail)) {
        if (yield select(onHomepage)) {
          yield put({ type: "GO_HOME" });
        } else if (yield select(showBrowseTabs)) {
          yield put({ type: "FOCUS_ON_BROWSE_TABS" });
        }
      } else {
        const index = yield select(prevRailIndex);
        yield put({ type: "RAILS.CHANGE_RAIL", index });
      }
      break;
    case "DOWN": {
      const index = yield select(nextRailIndex);
      yield animate("holdRail", railIndex.toString());
      yield put({ type: "RAILS.CHANGE_RAIL", index });
      break;
    }
    case "LEFT": {
      const index = yield select(prevTileIndex);
      yield put({ type: "RAILS.CHANGE_TILE", index });
      yield animate("moveLens", railIndex.toString());
      break;
    }
    case "RIGHT": {
      const index = yield select(nextTileIndex);
      yield put({ type: "RAILS.CHANGE_TILE", index });
      yield animate("moveLens", railIndex.toString());
      break;
    }
    case "SELECT": {
      const tile = yield select(selectedTile),
        action = userActionToReduxAction(tile.action);
      action && (yield put(action));
      break;
    }
    case "BACK":
      if (
        settings.backBehaviour === "twice-for-page-back" &&
        !(yield select(onFirstRailAndTile))
      ) {
        yield put({ type: "RAILS.RESET" });
      } else {
        yield put({ type: "BROWSE_BACK" });
      }
      break;
  }
}
