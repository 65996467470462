import { delay, put, takeLatest } from "sagas/effects";

function* onShow() {
  yield delay(3000);
  yield put({ type: "MINIGUIDE.HIDE" });
}

export function* miniGuideSaga() {
  yield takeLatest("MINIGUIDE.SHOW", onShow);
}
