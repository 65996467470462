import { JourneyId, LandscapeTile, MyListItem } from "types";

const JOURNEY_TO_MY_LIST_ITEM: Partial<{ [key in JourneyId]: MyListItem }> = {
  lineOfDuty: {
    itemType: "series",
    itemId: "b7059eb2-baff-4b2d-83fc-ca879b7740fe",
  },
};

export const journeyToMyListItem = (
  journeyId: JourneyId
): MyListItem | undefined => JOURNEY_TO_MY_LIST_ITEM[journeyId];

const MY_LIST_ITEM_TO_TILE: { [key: string]: LandscapeTile } = {
  "series/b7059eb2-baff-4b2d-83fc-ca879b7740fe": {
    type: "landscapeTile",
    title: "Line of Duty",
    imageUrl:
      "https://images.metadata.sky.com/pd-image/b7059eb2-baff-4b2d-83fc-ca879b7740fe/16-9/{width}",
    providerImageUrl:
      "https://images.metadata.sky.com/pd-logo/skychb_bbc1/408/84",
    hero: {
      mode: "standard",
      title: "Line of Duty",
      synopsis:
        "DS Steve Arnott is transferred to the anti-corruption unit after he refuses to participate in a cover-up that involves an innocent man getting shot in a police encounter.",
      heroImageUrl:
        "https://images.metadata.sky.com/pd-image/b7059eb2-baff-4b2d-83fc-ca879b7740fe/background/{width}",
      logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc1/408/84",
      metadata: "1h    ",
    },
    action: {
      type: "goToPage",
      pageType: "imagePage",
      pageId: "lineOfDuty",
    },
  },
};

export const myListItemToTile = ({ itemType, itemId }: MyListItem) =>
  MY_LIST_ITEM_TO_TILE[[itemType, itemId].join("/")];
