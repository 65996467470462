import { NavStep } from "types";
import { put, select, delay, animate } from "sagas/effects";
import { inJourneyTopLevel } from "selectors/nav";
import { onLastPage } from "selectors/journey";
import { switcherIsShowing } from "selectors/app";

export function* journeyNavigate(step: NavStep) {
  switch (step) {
    case "SELECT": {
      const onLastPg = yield select(onLastPage);
      if (!onLastPg) {
        yield put({ type: "JOURNEY.FORWARD" });
        yield animate("journeyForward");
      }
      break;
    }
    case "BACK": {
      // @ts-ignore
      yield* back();
      break;
    }
    case "ADD":
      yield put({ type: "MY_LIST.ADD_CURRENT" });
  }
}

function* back() {
  const inTopLevel = yield select(inJourneyTopLevel);
  if (inTopLevel) {
    const showSwitcher = yield select(switcherIsShowing);
    yield animate("fullscreenFadeOut");
    yield delay(450);
    yield put({ type: "TURN_OFF_FULLSCREEN" });
    if (showSwitcher) {
      yield put({ type: "JOURNEY.STOP", focusOnRecent: true });
    } else {
      yield delay(1000); // Avoids strange zooming out artifact
      yield put({ type: "JOURNEY.STOP" });
    }
  } else {
    yield put({ type: "JOURNEY.BACK" });
    yield animate("journeyBack");
  }
}
