import React, { useRef } from "react";
import { grid, rail as railDims } from "constants/dimensions";
import { CategoryTile } from "components/CategoryTile";
import { CoverTile } from "components/CoverTile";
import { LandscapeTile } from "components/LandscapeTile";
import { TitleTile } from "components/TitleTile";
import { PromoTile } from "components/PromoTile";
import { Text } from "components/Text";
import {
  maxRowX,
  horizMaskGradient,
  shouldShowRailTitle,
} from "utils/railHelpers";
import { Rail as RailType, Tile } from "types";
import { Container, TextContainer, Row, ItemContainer } from "./Rail.styles";
import { NowAndNextTile } from "components/NowAndNextTile";
import { RailLens } from "components/RailLens";
import { SquareTile } from "components/SquareTile";
import { useAnimations } from "hooks/useAnimations";
import gsap from "gsap";
import { boolToInt } from "utils/boolToInt";
import { wait } from "utils/wait";
import { transitions } from "constants/transitions";
import { useFocusedItemDims } from "hooks/useFocusedItemDims";
import { useDefaultAnimation } from "hooks/useDefaultAnimation";

const tileComponent = (tile: Tile, focused: boolean) => {
  switch (tile.type) {
    case "titleTile":
      return <TitleTile tile={tile} focused={focused} />;
    case "landscapeTile": {
      return <LandscapeTile tile={tile} focused={focused} />;
    }
    case "coverTile": {
      return <CoverTile tile={tile} focused={focused} />;
    }
    case "categoryTile": {
      return <CategoryTile tile={tile} focused={focused} />;
    }
    case "promoTile": {
      return <PromoTile tile={tile} focused={focused} />;
    }
    case "nowAndNextTile":
      return <NowAndNextTile tile={tile} focused={focused} />;
    case "squareTile":
      return <SquareTile tile={tile} focused={focused} />;
  }
};

const renderTile = (tile: Tile, index: number, focused: boolean) => (
  <ItemContainer key={index} focused={focused}>
    {tileComponent(tile, focused)}
  </ItemContainer>
);

interface RailProps {
  railIndex: number;
  focusedRailIndex: number;
  focusedTileIndex: number;
  rail: RailType;
  showFocusedGlassFrame: boolean;
}

export const Rail = ({
  railIndex,
  focusedRailIndex,
  focusedTileIndex,
  rail,
  showFocusedGlassFrame,
}: RailProps) => {
  const container = useRef(null),
    textContainer = useRef(null),
    row = useRef(null),
    focusedItemDims = useFocusedItemDims(row, focusedTileIndex),
    railIsFocused = railIndex === focusedRailIndex,
    showRailLens = showFocusedGlassFrame && railIsFocused,
    showTitle = shouldShowRailTitle(rail, focusedTileIndex),
    maximumRowX = maxRowX(row, rail.tiles.length, railDims.innerWidth),
    rowX = -Math.min(focusedItemDims.x, maximumRowX),
    lensX = Math.max(focusedItemDims.x - maximumRowX, 0),
    visible = railIndex >= focusedRailIndex;

  // Animate container
  useDefaultAnimation(
    container,
    {
      opacity: boolToInt(visible),
      marginTop: railDims.marginTop(showTitle || railIsFocused),
    },
    transitions.changeRail
  );

  // Animate text container
  useAnimations(
    {
      scope: railIndex.toString(),
      reset: params => gsap.set(textContainer.current, params),
      defaultAnimation: params =>
        gsap.to(textContainer.current, { ...params, ...transitions.moveLens }),
      uninterruptible: ["holdRail"],
      animations: {
        holdRail: () => wait(transitions.changeRail.duration),
      },
    },
    {
      y: railDims.titleOffset(showRailLens, rail.template),
      opacity: boolToInt(showTitle),
    }
  );

  // Animate row
  useAnimations(
    {
      scope: railIndex.toString(),
      reset: params => gsap.set(row.current, params),
      uninterruptible: ["holdRail"],
      animations: {
        holdRail: (_, timeline) =>
          timeline.fromTo(
            row.current,
            { "-webkit-mask-image": "" },
            { duration: transitions.changeRail.duration }
          ),
        moveLens: (params, timeline) =>
          timeline.to(row.current, {
            ...params,
            ...transitions.moveLens,
          }),
      },
    },
    {
      x: rowX,
      "-webkit-mask-image": horizMaskGradient(
        focusedItemDims.x - grid.column.gap,
        focusedItemDims.width + 2 * grid.column.gap,
        showRailLens
      ),
    }
  );

  return (
    <Container ref={container}>
      <TextContainer ref={textContainer}>
        <Text size="medium" weight="medium">
          {rail.title}
        </Text>
      </TextContainer>
      <Row ref={row}>
        {rail.tiles.map((tile, index) => renderTile(tile, index, false))}
      </Row>

      {showRailLens && (
        <RailLens
          {...{
            x: lensX - grid.column.gap,
            y: -railDims.extraFocusedHeight(rail.template) / 2,
            index: focusedTileIndex,
            extraWidth: 2 * grid.column.gap,
            height: railDims.focusedHeight(rail.template),
            animationScope: railIndex.toString(),
          }}
        >
          {rail.tiles.map((tile, index) => renderTile(tile, index, true))}
        </RailLens>
      )}
    </Container>
  );
};
