import pick from "lodash/pick";
import { dispatch, sagaMiddleware, store } from "store";
import { rootSaga } from "sagas";
import { BrowserVoiceListener } from "services/browserVoiceListener";
import { ExternalVoiceListener } from "services/externalVoiceListener";
import { config } from "utils/config";
import { VoiceListener } from "services/voiceListener";
import { onStoreChange } from "utils/onStoreChange";
import { initialSettingsState } from "reducers/settings";
import { localStore } from "utils/localStore";
import { KeyListener, Tier } from "services/keyListener";
import { State } from "reducers";
import { UrlParser } from "services/UrlParser";

export const initializeApp = () => {
  initSagas();
  initBrowserVoiceListener();
  initExternalVoiceListener();
  initSettings();
  initKeyListener();
  initFromRoute();

  return function cleanup() {};
};

const initSagas = () => {
  sagaMiddleware.run(rootSaga);
};

const initBrowserVoiceListener = () => {
  if (config.dialogFlow.enabled) {
    try {
      const listener = new BrowserVoiceListener();
      initVoiceListener(listener);
      onStoreChange(
        s => s.toast.status === "listening",
        isListening =>
          isListening ? listener.commandMode() : listener.wakewordMode()
      );
    } catch (error) {
      alert(error);
    }
  }
};

const initExternalVoiceListener = () => {
  if (config.pusher.enabled) {
    initVoiceListener(new ExternalVoiceListener());
  }
};

const initVoiceListener = (voiceListener: VoiceListener) => {
  voiceListener.onAddToMyList(({ asr }) => {
    dispatch({ type: "VOICE.ADD_TO_MY_LIST", asr });
  });
  voiceListener.onGoHome(({ asr }) => {
    dispatch({ type: "VOICE.GO_HOME", asr });
  });
  voiceListener.onGoBack(({ asr }) => {
    dispatch({ type: "VOICE.GO_BACK", asr });
  });
  voiceListener.onGoToPage(({ asr, pageId }) =>
    dispatch({ type: "VOICE.GO_TO_PAGE", asr, pageId })
  );
  voiceListener.onMute(({ asr }) => {
    dispatch({ type: "VOICE.MUTE", asr });
  });
  voiceListener.onNotRecognised(({ asr }) => {
    dispatch({ type: "VOICE.NOT_RECOGNISED", asr });
  });
  voiceListener.onPlay(({ asr, contentId }) =>
    dispatch({ type: "VOICE.PLAY", asr, contentId })
  );
  voiceListener.onUnmute(({ asr }) => {
    dispatch({ type: "VOICE.UNMUTE", asr });
  });
  voiceListener.onVolumeChange(({ asr, value }) =>
    dispatch({ type: "VOICE.VOLUME_CHANGE", asr, value })
  );
  voiceListener.onVolumeSet(({ asr, value }) =>
    dispatch({ type: "VOICE.VOLUME_SET", asr, value })
  );
  voiceListener.onWakeWord(() => {
    dispatch({ type: "VOICE.WAKE" });
  });
};

const initSettings = () => {
  const settings = pick(localStore.settings, Object.keys(initialSettingsState));
  dispatch({ type: "SETTINGS.UPDATE", settings });
};

const initKeyListener = () => {
  const getTiers = (state: State) => state.settings.buttonPressTiers;
  const keyListener = new KeyListener();
  subscribeToKeyListener(keyListener, getTiers(store.getState()));
  onStoreChange(getTiers, tiers => subscribeToKeyListener(keyListener, tiers));
};

const subscribeToKeyListener = (keyListener: KeyListener, tiers: Tier[]) => {
  keyListener.unsubscribe();
  keyListener.updateTiers(tiers);
  keyListener.subscribe(({ button, pressType }) => {
    // Disable if settings is open
    if (store.getState().app.settingsOpen) return;
    if (pressType === "SHORT") {
      // Short presses
      switch (button) {
        case "LEFT":
        case "RIGHT":
        case "UP":
        case "DOWN":
        case "SELECT":
        case "HOME":
        case "BACK":
        case "ADD":
          dispatch({ type: "NAVIGATE", step: button });
          break;
        case "VOLUME_UP":
          dispatch({ type: "CHANGE_VOLUME", value: 1 });
          break;
        case "VOLUME_DOWN":
          dispatch({ type: "CHANGE_VOLUME", value: -1 });
          break;
        case "SETTINGS":
          dispatch({ type: "SETTINGS.TOGGLE" });
          break;
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
        case "7":
        case "8":
        case "9":
          dispatch({ type: "SHORTCUT", key: button });
          break;
      }
    } else {
      // Long presses
      switch (button) {
        case "LEFT":
          dispatch({ type: "NAVIGATE", step: "LONG_LEFT" });
          break;
        case "RIGHT":
          dispatch({ type: "NAVIGATE", step: "LONG_RIGHT" });
          break;
        case "UP":
          dispatch({ type: "NAVIGATE", step: "LONG_UP" });
          break;
        case "DOWN":
          dispatch({ type: "NAVIGATE", step: "LONG_DOWN" });
          break;
        case "SETTINGS": // In case RCU config is set to only long presses
          dispatch({ type: "SETTINGS.TOGGLE" });
          break;
      }
    }
  });
};

const initFromRoute = () => {
  const parser = new UrlParser({
    "page/:pageId": ({ pageId }) =>
      dispatch({ type: "ENTRYPOINT.START_ON_RAIL", pageId, railIndex: 0 }),
    "page/:pageId/rail/:railIndex": ({ pageId, railIndex }) =>
      dispatch({
        type: "ENTRYPOINT.START_ON_RAIL",
        pageId,
        railIndex: parseInt(railIndex),
      }),
  });
  parser.parse(window.location.hash);
};
