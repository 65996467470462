import { TvGuidePage } from "types";
import rail from "./rail";

export default {
  type: "tvGuidePage",
  channelIds: [
    "2006", // BBC TWO
    "1800", // Channel 5
    "2002", // BBC ONE
    "1621", // Channel 4
    "1402", // Sky One
    "1412", // Sky Atlantic
    "6000", // ITV
    "1301", // Sky Sports Main Event
    "1303", // Sky Sports Premier League
    "2011", // BBC News
    "1404", // Sky News
    "1314", // Sky Sports News
    "2018", // BBC FOUR
    "6240", // ITV 2
    "2207", // Sky Crime
  ],
  rail,
  longPressUpPageId: "tvGuide_tvShows",
  longPressDownPageId: "tvGuide_movies",
  title: {
    text: "Favourites",
  },
} as TvGuidePage;
