import { Action } from "types/actions";
import { FocusArea } from "types";

export type AppState = Readonly<{
  fullscreenMode: boolean;
  pageBackgroundSrc: string;
  focusArea: FocusArea;
  showSwitcher: boolean;
  introVideoUrl?: string;
  settingsOpen: boolean;
}>;

export const initialAppState = {
  fullscreenMode: false,
  pageBackgroundSrc: "./assets/images/backgrounds/llama-wallpaper.jpg",
  focusArea: "switcher" as FocusArea,
  showSwitcher: true,
  settingsOpen: false,
};

export const appReducer = (
  state: AppState = initialAppState,
  action: Action
) => {
  switch (action.type) {
    case "JOURNEY.START":
      return { ...state, fullscreenMode: true };

    case "JOURNEY.STOP":
      return { ...state, fullscreenMode: false };

    case "GO_TO_PAGE":
      return { ...state, showSwitcher: false, fullscreenMode: false };

    case "SHOW_SWITCHER":
      return { ...state, showSwitcher: true };

    case "HIDE_SWITCHER":
      return { ...state, showSwitcher: false };

    case "FOCUS_ON_SWITCHER":
      return {
        ...state,
        focusArea: "switcher" as FocusArea,
        showSwitcher: true,
        fullscreenMode: false,
      };

    case "FOCUS_ON_BROWSE":
      return { ...state, focusArea: "browse" as FocusArea };

    case "FOCUS_ON_BROWSE_TABS":
      return { ...state, focusArea: "browseTabs" as FocusArea };

    case "TURN_ON_FULLSCREEN":
      return { ...state, fullscreenMode: true };

    case "TURN_OFF_FULLSCREEN":
      return { ...state, fullscreenMode: false };

    case "INTRO.PLAY":
      return { ...state, introVideoUrl: action.videoUrl };

    case "INTRO.ENDED":
      return { ...state, introVideoUrl: undefined };

    case "SETTINGS.TOGGLE":
      return { ...state, settingsOpen: !state.settingsOpen };

    default:
      return state;
  }
};
