import { VoiceListener, SpecificCallback } from "services/voiceListener";
import { SpeechRecognizer } from "services/speechRecognizer";
import { DialogFlow } from "services/dialogFlow";
import uuidv4 from "uuid/v4";
import {
  validateNumber,
  validatePageId,
  validateJourneyId,
} from "types/validators";

type ListeningMode = "wakeword" | "command";

export class BrowserVoiceListener implements VoiceListener {
  private wakeword = "hello sky";
  private speechRecognizer = new SpeechRecognizer();
  private dialogFlow = new DialogFlow(uuidv4());
  private listeningMode: ListeningMode = "wakeword";
  private callbacks: {
    addToMyList?: SpecificCallback<"onAddToMyList">;
    goHome?: SpecificCallback<"onGoHome">;
    goBack?: SpecificCallback<"onGoBack">;
    goToPage?: SpecificCallback<"onGoToPage">;
    play?: SpecificCallback<"onPlay">;
    mute?: SpecificCallback<"onMute">;
    notRecognised?: SpecificCallback<"onNotRecognised">;
    unmute?: SpecificCallback<"onUnmute">;
    volumeChange?: SpecificCallback<"onVolumeChange">;
    volumeSet?: SpecificCallback<"onVolumeSet">;
    wakeWord?: SpecificCallback<"onWakeWord">;
  } = {};

  constructor() {
    this.speechRecognizer.onSpeech(async phrase => {
      console.info(`Speech recognised: "${phrase}"`);
      if (this.listeningMode === "wakeword") {
        this.handleWakeword(phrase);
      } else {
        this.handleCommand(phrase);
      }
    });
  }

  private handleWakeword(phrase: string) {
    if (phrase.toLowerCase().trim() === this.wakeword) {
      this.callbacks.wakeWord?.();
    }
  }

  private async handleCommand(phrase: string) {
    const { action, parameters } = await this.dialogFlow.detectIntent(phrase);
    if (action === "input.unknown") {
      this.callbacks.notRecognised?.({ asr: phrase });
    } else {
      this.callCallback(action, parameters, phrase);
    }
  }

  private callCallback(
    action: string,
    parameters: { [key: string]: unknown },
    phrase: string
  ) {
    try {
      switch (action) {
        case "add-to-my-list":
          this.callbacks.addToMyList?.({
            asr: phrase,
          });
          break;
        case "go-to-page":
          this.callbacks.goToPage?.({
            asr: phrase,
            pageId: validatePageId(parameters.pageId, "pageId"),
          });
          break;
        case "go-home":
          this.callbacks.goHome?.({
            asr: phrase,
          });
          break;
        case "go-back":
          this.callbacks.goBack?.({
            asr: phrase,
          });
          break;
        case "play":
          this.callbacks.play?.({
            asr: phrase,
            contentId: validateJourneyId(parameters.contentId, "journeyId"),
          });
          break;
        case "mute":
          this.callbacks.mute?.({
            asr: phrase,
          });
          break;
        case "unmute":
          this.callbacks.unmute?.({
            asr: phrase,
          });
          break;
        case "volume-up":
          this.callbacks.volumeChange?.({
            asr: phrase,
            value: validateNumber(parameters.amount, "amount"),
          });
          break;
        case "volume-down":
          this.callbacks.volumeChange?.({
            asr: phrase,
            value: -validateNumber(parameters.amount, "amount"),
          });
          break;
        case "volume-set":
          this.callbacks.volumeSet?.({
            asr: phrase,
            value: validateNumber(parameters.amount, "amount"),
          });
          break;
        default:
          console.warn(
            "Don't know how to deal with DialogFlow result",
            action,
            parameters
          );
      }
    } catch (error) {
      console.error(error);
    }
  }

  commandMode() {
    this.listeningMode = "command";
  }

  wakewordMode() {
    this.listeningMode = "wakeword";
  }

  onAddToMyList(callback: SpecificCallback<"onAddToMyList">) {
    this.callbacks.addToMyList = callback;
  }

  onGoHome(callback: SpecificCallback<"onGoHome">) {
    this.callbacks.goHome = callback;
  }

  onGoBack(callback: SpecificCallback<"onGoBack">) {
    this.callbacks.goBack = callback;
  }

  onGoToPage(callback: SpecificCallback<"onGoToPage">) {
    this.callbacks.goToPage = callback;
  }

  onPlay(callback: SpecificCallback<"onPlay">) {
    this.callbacks.play = callback;
  }

  onMute(callback: SpecificCallback<"onMute">) {
    this.callbacks.mute = callback;
  }

  onNotRecognised(callback: SpecificCallback<"onNotRecognised">) {
    this.callbacks.notRecognised = callback;
  }

  onUnmute(callback: SpecificCallback<"onUnmute">) {
    this.callbacks.unmute = callback;
  }

  onVolumeChange(callback: SpecificCallback<"onVolumeChange">) {
    this.callbacks.volumeChange = callback;
  }

  onVolumeSet(callback: SpecificCallback<"onVolumeSet">) {
    this.callbacks.volumeSet = callback;
  }

  onWakeWord(callback: SpecificCallback<"onWakeWord">) {
    this.callbacks.wakeWord = callback;
  }
}
