import { SpecificAction } from "types/actions";
import { call, delay, put, takeLatest } from "sagas/effects";
import { sounds } from "services/sounds";

export function* toastSaga() {
  yield takeLatest("SHOW_TOAST", onShowToast);
  yield takeLatest(["SHOW_TOAST", "CHANGE_TOAST_STATUS"], hideAfterDelay);
}

function* onShowToast(action: SpecificAction<"SHOW_TOAST">) {
  switch (action.status) {
    case "success":
    case "error":
      yield call(
        action.status === "success" ? sounds.voiceSuccess : sounds.voiceError
      );
      yield delay(1200);
      yield put({ type: "CHANGE_TOAST_STATUS", status: "listening" });
      break;
  }
}

function* hideAfterDelay() {
  yield delay(8000);
  yield put({ type: "HIDE_TOAST_MESSAGE" });
}
