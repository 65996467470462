import Pusher from "pusher-js";
import * as PusherTypes from "pusher-js";
import { config } from "utils/config";
import { VoiceListener, SpecificCallback } from "./voiceListener";

// We can stub this in Cypress tests
const window = global as any;
if (!window.newPusherClient) {
  window.newPusherClient = (key: string, options?: PusherTypes.Options) =>
    new Pusher(key, options);
}

Pusher.logToConsole = config.pusher.logToConsole;

export class ExternalVoiceListener implements VoiceListener {
  private pusher: Pusher;
  private channel: PusherTypes.Channel;

  constructor() {
    this.pusher = window.newPusherClient(config.pusher.key, {
      cluster: "eu",
      forceTLS: true,
    });
    this.channel = this.pusher.subscribe("voice");
  }

  onAddToMyList(callback: SpecificCallback<"onAddToMyList">) {
    this.channel.bind("add_to_my_list", callback);
  }

  onGoHome(callback: SpecificCallback<"onGoHome">) {
    this.channel.bind("go_home", callback);
  }

  onGoBack(callback: SpecificCallback<"onGoBack">) {
    this.channel.bind("go_back", callback);
  }

  onGoToPage(callback: SpecificCallback<"onGoToPage">) {
    this.channel.bind("go_to_page", callback);
  }

  onPlay(callback: SpecificCallback<"onPlay">) {
    this.channel.bind("play", callback);
  }

  onMute(callback: SpecificCallback<"onMute">) {
    this.channel.bind("mute", callback);
  }

  onNotRecognised(callback: SpecificCallback<"onNotRecognised">) {
    this.channel.bind("not_recognised", callback);
  }

  onUnmute(callback: SpecificCallback<"onUnmute">) {
    this.channel.bind("unmute", callback);
  }

  onVolumeChange(callback: SpecificCallback<"onVolumeChange">) {
    this.channel.bind("volume_change", callback);
  }

  onVolumeSet(callback: SpecificCallback<"onVolumeSet">) {
    this.channel.bind("volume_set", callback);
  }

  onWakeWord(callback: SpecificCallback<"onWakeWord">) {
    this.channel.bind("wakeword", callback);
  }
}
