import { State } from "reducers";
import { TVGuidePageState } from "reducers/browse/pages/tvGuide";
import { Hero } from "../../types";
import { getPage } from "../../data/pages";
import { slotIndexToDate } from "helpers/tvGuideHelpers";

const pageState = ({ browse: { pageState } }: State): TVGuidePageState => {
  if (pageState.pageType !== "tvGuidePage") {
    throw new Error(
      `Expected page type to be tvGuidePage, not ${pageState.pageType}`
    );
  }
  return pageState;
};

const page = (state: State) => {
  const { pageId, pageType } = pageState(state);
  return getPage(pageId, pageType);
};

export const onFirstChannel = (state: State) =>
  pageState(state).channelIndex === 0;

export const onLastChannel = (state: State) => {
  const { channelIndex, schedule } = pageState(state);
  return schedule && channelIndex === schedule.channels.length - 1;
};

export const selectedEvent = (state: State) => {
  const { schedule, eventIndex, channelIndex } = pageState(state);
  if (!schedule) return;
  return schedule.channels[channelIndex].events[eventIndex];
};

const selectedChannel = (state: State) => {
  const { channelIndex, schedule } = pageState(state);
  if (!schedule) return;
  return schedule.channels[channelIndex].channel;
};

export const onNowEvent = (state: State) => {
  const { onNowLookup } = pageState(state),
    channel = selectedChannel(state);
  return onNowLookup[channel?.id];
};

export const focusArea = (state: State) => pageState(state).focusArea;

export const pageHero = (state: State): Hero =>
  pageState(state).hero || { mode: "none" };

export const railHero = (state: State): Hero => {
  const { tileIndex } = pageState(state);
  const { rail } = page(state);
  return rail.tiles[tileIndex]?.hero || { mode: "none" };
};

export const selectedDate = (state: State) => {
  const { midnight, slotIndex } = pageState(state);
  return slotIndexToDate(slotIndex, midnight);
};

export const longPressUpPageId = (state: State) =>
  page(state).longPressUpPageId;

export const longPressDownPageId = (state: State) =>
  page(state).longPressDownPageId;

export const selectedTile = (state: State) => {
  const { tileIndex } = pageState(state),
    { rail } = page(state);
  return rail.tiles[tileIndex];
};
