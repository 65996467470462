import { TvGuidePage } from "types";
import rail from "./rail";

export default {
  type: "tvGuidePage",
  channelIds: [
    "2002", // BBC ONE
    "2006", // BBC TWO
    "6000", // ITV
    "1621", // Channel 4
    "1800", // Channel 5
    "1402", // Sky One
    "2201", // Sky Witness
    "1412", // Sky Atlantic
    "2617", // W
    "2304", // Gold
    "2306", // Dave
    "2510", // Comedy Central
    "1177", // Sky Comedy
    "1127", // Sky Documentaries
    "2018", // BBC Four
  ],
  rail,
  longPressUpPageId: "tvGuide_kids",
  longPressDownPageId: "tvGuide_favouriteChannels",
  title: {
    text: "TV Shows",
  },
  linearEventOverrides: {
    2002: {
      title: "Line of Duty",
      programmeId: "b1409cad-3e0c-41ae-aafb-823eb3ecccf4",
    },
    2006: {
      title: "What We Do in the Shadows",
      programmeId: "1856320d-c1ee-47c9-af36-d0ca7d281838",
    },
  },
} as TvGuidePage;
