import { TvGuidePage } from "types";
import rail from "./rail";

export default {
  type: "tvGuidePage",
  channelIds: [
    "1404", // Sky News
    "2011", // BBC News
    "2072", // BBC Parliament
    "3611", // CNBC
    "5602", // CNN
    "4645", // Sky News Arabia
  ],
  rail,
  longPressUpPageId: "tvGuide_sports",
  longPressDownPageId: "tvGuide_kids",
  title: {
    text: "News",
  },
} as TvGuidePage;
