import React from "react";
import { Text } from "../Text";
import {
  Container,
  NowContainer,
  NextContainer,
  Logo,
} from "./NowAndNextTile.styles";
import { ProgressBar } from "components/ProgressBar";
import { formatTime } from "utils/timeHelpers";
import { NowAndNextTile as NowAndNextTileType } from "types";

interface Props {
  focused: boolean;
  tile: NowAndNextTileType;
}

export const NowAndNextTile = ({
  focused,
  tile: {
    nextTitle,
    nextStart,
    nowTitle,
    nowStart,
    providerImageUrl,
    progressPercent,
  },
}: Props) => {
  return (
    <Container focused={focused}>
      <NowContainer>
        <Logo src={providerImageUrl} alt="Channel Logo" />
        <Text size="medium" weight="medium" clamp={1}>
          {nowTitle}
        </Text>
        <Text
          size="small"
          weight={focused ? "medium" : "regular"}
          clamp={1}
          style={{ lineHeight: "27px", marginBottom: 16 }}
        >
          Started at {formatTime(nowStart)}
        </Text>
        <ProgressBar height={4} percentage={progressPercent} />
      </NowContainer>
      <NextContainer>
        <Text size="medium" weight="medium" clamp={1}>
          {nextTitle}
        </Text>
        <Text size="small" weight={focused ? "medium" : "regular"} clamp={1}>
          Starts at {formatTime(nextStart)}
        </Text>
      </NextContainer>
    </Container>
  );
};
