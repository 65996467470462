import { Action } from "types/actions";

export type MiniGuideState = Readonly<{
  visible: boolean;
}>;

export const initialMiniGuideState = {
  visible: false,
};

export const miniGuideReducer = (
  state: MiniGuideState = initialMiniGuideState,
  action: Action
) => {
  switch (action.type) {
    case "MINIGUIDE.SHOW":
      return { ...state, visible: true };
    case "MINIGUIDE.HIDE":
      return { ...state, visible: false };
    default:
      return state;
  }
};
