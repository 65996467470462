import { put } from "sagas/effects";
import { SpecificAction } from "types/actions";

export function* shortcut({ key }: SpecificAction<"SHORTCUT">) {
  switch (key) {
    case "1":
      yield put({ type: "VOICE.WAKE" });
      break;
    case "2":
      yield put({
        type: "VOICE.GO_TO_PAGE",
        pageId: "tv",
        asr: "Go to TV Shows",
      });
      break;
    case "3":
      yield put({
        type: "VOICE.PLAY",
        contentId: "landlord",
        asr: "Play the Reluctant Landlord",
      });
      break;
    case "4":
      yield put({
        type: "VOICE.VOLUME_CHANGE",
        value: -50,
        asr: "Volume down by 50",
      });
      break;
    case "5":
      yield put({
        type: "VOICE.MUTE",
        asr: "Mute the audio",
      });
      break;
    case "6":
      yield put({
        type: "VOICE.UNMUTE",
        asr: "Unmute the audio",
      });
      break;
    case "7":
    case "8":
    case "9":
      yield put({
        type: "VOICE.GO_BACK",
        asr: "Go back",
      });
      break;
  }
}
