import React from "react";
import { Text } from "../Text";
import { ProgressBar } from "../ProgressBar";
import {
  Container,
  ContentContainer,
  MediaContainer,
  Image,
  Logo,
  LogoContainer,
} from "./SwitcherTile.styles";
import { imageUrlFromTemplate } from "utils/imageUrlFromTemplate";
import { switcherTile } from "constants/dimensions";

type Props = {
  focused: boolean;
  imageUrl?: string;
  providerImageUrl?: string;
  title?: string;
  tag?: string;
  progress?: number;
};

export const SwitcherTile = ({
  focused,
  imageUrl,
  providerImageUrl,
  title,
  tag,
  progress,
}) => {
  const hasContent = !!(title || tag || providerImageUrl || progress);
  const hasProgressBar = !!progress;
  return (
    <Container focused={focused}>
      <MediaContainer>
        <Image
          alt=""
          loading="lazy"
          src={imageUrlFromTemplate(imageUrl, switcherTile)}
        />
      </MediaContainer>
      {hasContent && (
        <ContentContainer>
          <LogoContainer>
            <Logo src={providerImageUrl} alt="" />
          </LogoContainer>
          <Text size="medium" clamp={1} weight="medium">
            {title}
          </Text>
          <Text size="small" clamp={1} weight={focused ? "medium" : "regular"}>
            {tag}
          </Text>
          {hasProgressBar && (
            <ProgressBar
              height={5}
              percentage={progress}
              styles={{ marginTop: 12 }}
            />
          )}
        </ContentContainer>
      )}
    </Container>
  );
};
