import { Rail } from "types";

export default {
  type: "rail",
  uuid: "69e6024a-0da0-4e0b-9467-3492f92d6366",
  template: "tile-landscape",
  title: "Restart what's on now",
  tiles: [
    {
      type: "landscapeTile",
      title: "Line of Duty",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/b7059eb2-baff-4b2d-83fc-ca879b7740fe/16-9/{width}",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc1/408/84",
      hero: {
        mode: "standard",
        title: "Line of Duty",
        synopsis:
          "DS Steve Arnott is transferred to the anti-corruption unit after he refuses to participate in a cover-up that involves an innocent man getting shot in a police encounter.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/b7059eb2-baff-4b2d-83fc-ca879b7740fe/background/{width}",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc1/408/84",
        metadata: "1h   ",
      },
      action: {
        type: "startJourney",
        journeyId: "lineOfDuty",
      },
    },
    {
      type: "landscapeTile",
      title: "What We Do in the Shadows",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/2f30c794-7dff-4d98-b461-85c318b6aca5/16-9/{width}",
      hero: {
        mode: "standard",
        title: "What We Do in the Shadows",
        metadata: "1h  ",
        synopsis:
          "Resurrection. The vampires enlist the services of a local necromancer. Contains some strong language and some violence.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/2f30c794-7dff-4d98-b461-85c318b6aca5/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
    },
    {
      type: "landscapeTile",
      title: "Blue Bloods",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/e83c14be-be54-4a9b-90a1-fb75d2eade2f/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Blue Bloods",
        synopsis:
          "The Reagans are an Irish Catholic family of cops. Each member represents different aspects of police work and law, with their lives interweaving occasionally.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/e83c14be-be54-4a9b-90a1-fb75d2eade2f/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Office (US)",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/e4aae372-6f3f-4848-97f9-982b30fcc9ed/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Office (US)",
        synopsis:
          "A motley group of office workers go through hilarious misadventures at the Scranton, Pennsylvania, branch of the Dunder Mifflin Paper Company.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/e4aae372-6f3f-4848-97f9-982b30fcc9ed/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycomedy/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycomedy/408/84",
    },
    {
      type: "landscapeTile",
      title: "Dateline",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/49a16d21-4c44-4feb-bd97-757360953cac/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Dateline",
        synopsis:
          "`Dateline' has been a staple of the newsmagazine genre since premiering in 1992. Given how long it's been on the air, the show has an extensive archive of in-depth news stories and investigative journalism. This syndicated version offers viewers another chance to watch some of the show's previous content, serving up real-life mysteries and in-depth investigations from the show's 20-plus years on the air. Cases involving murders and missing people are frequent topics on the series that has won multiple Emmys in the news and documentary category.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/49a16d21-4c44-4feb-bd97-757360953cac/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycrime/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycrime/408/84",
    },
  ],
} as Rail;
