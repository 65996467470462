import { Rail } from "types";

export default {
  type: "rail",
  uuid: "most-recent-uuid", // NOTE THIS IS BEING USED IN THE CODE!
  template: "tile-landscape",
  title: "Most Recent",
  tiles: [
    {
      type: "landscapeTile",
      title: "Top Gear",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/7a549252-39d1-43c2-a95d-c01cd74079f4/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Top Gear",
        synopsis:
          "This fast-paced and stunt-filled motor show tests whether cars, both mundane and extraordinary, live up to their manufacturers' claims. ",
        metadata: "1h ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/7a549252-39d1-43c2-a95d-c01cd74079f4/background/{width}",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc1/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc1/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Angry Birds Movie 2",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/619902e2-45b7-409e-a6b8-744e4fc9d8c2/16-9/{width}",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      hero: {
        mode: "standard",
        title: "The Angry Birds Movie 2",
        synopsis:
          "The flightless birds and scheming green pigs take their beef to the next level. With the voice of Peter Dinklage. Flashing images. (2019)(93 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/619902e2-45b7-409e-a6b8-744e4fc9d8c2/background/1428",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        metadata: "1h 45m    ",
      },
    },
    {
      type: "landscapeTile",
      title: "The Reluctant Landlord",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/4b6bc808-1bf1-4ab5-b5f5-3846cd694c05/16-9/{width}",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
      action: {
        type: "goToPage",
        pageType: "imagePage",
        pageId: "landlord",
      },
      hero: {
        mode: "standard",
        title: "The Reluctant Landlord",
        synopsis:
          "Romesh quickly learns pulling pints is not for him when he has to take over his late dad's pub. What he wants is to be a hip-hop DJ and no amount of emotional blackmail from his wife Natasha and his kids will see him give this new venture his all.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/4b6bc808-1bf1-4ab5-b5f5-3846cd694c05/background/1428",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
        metadata: "1h 45m    ",
      },
    },
    {
      type: "landscapeTile",
      title: "Whale Wisdom",
      imageUrl: "assets/images/content/Whale Wisdom/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_all4/408/84",
      hero: {
        mode: "standard",
        title: "Whale Wisdom",
        synopsis:
          "These giants of the ocean have amazing skills and intelligence that humans can never truly understand. Dive beneath the waves as filmmaker Rick Rosenthal reveals just what whales are capable of.",
        heroImageUrl: "assets/images/content/Whale Wisdom/hero.jpg",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_all4/408/84",
        metadata: "1h 45m    ",
      },
    },
    {
      type: "landscapeTile",
      title: "Ray Donovan",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/344f2c21-2d55-47a2-a098-6b5e85a10116/16-9/{width}",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      hero: {
        mode: "standard",
        title: "Ray Donovan",
        synopsis:
          "Ray struggles to fix himself and his clients as he's forced to find answers to long-buried questions. Season seven of the award-winning drama starring Liev Schreiber and Jon Voight.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/344f2c21-2d55-47a2-a098-6b5e85a10116/background/1428",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
        metadata: "1h 45m    ",
      },
    },
    {
      type: "landscapeTile",
      title: "The Good Doctor",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/95c56583-9ccc-467e-8019-e7f628f73ae6/16-9/{width}",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skywitness/408/84",
      hero: {
        mode: "standard",
        title: "The Good Doctor",
        synopsis:
          "Shaun navigates a huge new development in his love life in the drama-filled third series of the hit medical show. ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/95c56583-9ccc-467e-8019-e7f628f73ae6/background/1428",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skywitness/408/84",
        metadata: "1h 45m    ",
      },
    },
    {
      type: "landscapeTile",
      title: "First Dates",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/5582f8f2-39b0-446f-be3f-fa7a1877508e/16-9/{width}",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_channel4/408/84",
      hero: {
        mode: "standard",
        title: "First Dates",
        synopsis:
          "Fred Sirieix helps a new batch of hopeful singletons in their search for love as he throws open the restaurant doors for the show’s 13th series.",
        heroImageUrl: "assets/images/content/First Dates/hero.jpg",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_channel4/408/84",
        metadata: "1h 45m    ",
      },
    },
    {
      type: "landscapeTile",
      title: "The Flash",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/c3eb3b35-6a30-40ff-a61d-82a2442d96a4/16-9/{width}",
      providerImageUrl:
        "https://cdn.skyq.sky.com/uk/epg/live/media/logos/netflix/v1/logo_365x75.png",
      hero: {
        mode: "standard",
        title: "The Flash",
        synopsis:
          "Barry Allen, a forensic scientist with the Central City police force, is struck by lightning in a freak accident. When he wakes up after nine months, he discovers that he can achieve great speeds.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/c3eb3b35-6a30-40ff-a61d-82a2442d96a4/background/1428",
        logoUrl:
          "https://cdn.skyq.sky.com/uk/epg/live/media/logos/netflix/v1/logo_365x75.png",
        metadata: "1h 45m    ",
      },
    },
    {
      type: "landscapeTile",
      title: "Aladdin",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/73b8e79a-efdc-4c8d-9951-3687139b2d75/16-9/{width}",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      hero: {
        mode: "standard",
        title: "Aladdin",
        synopsis:
          "Live-action re-imagining of the Disney classic about a street urchin who unleashes the power of a genie sparking a magical adventure. With Will Smith. (2019)(128 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/73b8e79a-efdc-4c8d-9951-3687139b2d75/background/1428",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        metadata: "1h 45m    ",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "titleArt",
        title: "Aladdin",
        synopsis:
          "Live-action re-imagining of the Disney classic about a street urchin who unleashes the power of a genie sparking a magical adventure. With Will Smith. (2019)(128 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/73b8e79a-efdc-4c8d-9951-3687139b2d75/background/1428",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        metadata: "1h 45m    ",
      },
    },
  ],
} as Rail;
