import { select, put } from "sagas/effects";
import { SpecificAction } from "types/actions";
import { inAJourney } from "selectors/nav";
import { journeyNavigate } from "sagas/navigate/journey";
import { switcherNavigate } from "sagas/navigate/switcher";
import { browseNavigate } from "sagas/navigate/browse";
import { browseTabsNavigate } from "sagas/navigate/browseTabs";
import { focusArea } from "selectors/app";

export function* navigate({ step }: SpecificAction<"NAVIGATE">) {
  switch (step) {
    case "HOME":
      yield put({ type: "GO_HOME" });
      break;
    default:
      if (yield select(inAJourney)) {
        yield journeyNavigate(step);
      } else {
        switch (yield select(focusArea)) {
          case "switcher":
            yield switcherNavigate(step);
            break;
          case "browse":
            yield browseNavigate(step);
            break;
          case "browseTabs":
            yield browseTabsNavigate(step);
            break;
        }
      }
  }
}
