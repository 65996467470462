import React from "react";
import { Global, css } from "@emotion/core";
import { Provider } from "./SkyProvider.styles";

export const SkyProvider = ({ children }) => (
  <>
    <Global
      styles={css`
        @font-face {
          font-family: "Sky Text Regular";
          src: url("./assets/fonts/SkyText_Rg.ttf") format("truetype");
          font-weight: normal;
          font-style: normal;
        }

        @font-face {
          font-family: "Sky Text Medium";
          src: url("./assets/fonts/SkyText_Md.ttf") format("woff2");
          font-weight: normal;
          font-style: normal;
        }

        @font-face {
          font-family: "Sky Text Light";
          src: url("./assets/fonts/SkyText_Lt.ttf") format("woff2");
          font-weight: normal;
          font-style: normal;
        }

        body {
          margin: 0;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          box-sizing: border-box;
        }

        *,
        *:before,
        *:after {
          box-sizing: inherit;
        }

        code {
          font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
            monospace;
        }
      `}
    />
    <Provider>{children}</Provider>
  </>
);
