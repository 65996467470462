import { MyListItem } from "types";
import { Action } from "types/actions";

export type MyListState = Readonly<{
  items: MyListItem[];
}>;

export const initialMyListState = { items: [] };

const alreadyInMyList = ({ itemId }: MyListItem, items: MyListItem[]) =>
  items.some(i => i.itemId === itemId);

export const myListReducer = (
  state: MyListState = initialMyListState,
  action: Action
) => {
  switch (action.type) {
    case "MY_LIST.ADD":
      return alreadyInMyList(action.item, state.items)
        ? state
        : { ...state, items: [...state.items, action.item] };
    default:
      return state;
  }
};
