import { RailsPage } from "types";
import apps from "./apps";
import becauseYouWatched from "./becauseYouWatched";
import disney from "./disney";
import genres from "../skyCinema/genres";
import mustSeeMovies from "./mustSeeMovies";
import netflix from "./netflix";
import nowAndNext from "./nowAndNext";
import recordings from "./recordings";
import skyCinema from "./skyCinema";
import skyStore from "./skyStore";
import topPicks from "./topPicks";
import trendingThisWeek from "./trendingThisWeek";
import ultraHd from "./ultraHd";

export default {
  type: "railsPage",
  rails: [
    topPicks,
    mustSeeMovies,
    genres,
    trendingThisWeek,
    becauseYouWatched,
    skyCinema,
    skyStore,
    netflix,
    disney,
    recordings,
    nowAndNext,
    ultraHd,
    apps,
  ],
  title: {
    text: "Movies",
  },
} as RailsPage;
