import styled from "@emotion/styled";
import { screen } from "constants/dimensions";

export const TrickPlay = styled.div`
  width: ${screen.width}px;
  height: ${screen.height}px;
  background: url(assets/images/video-player/trickplay.png);
  position: absolute;
  top: 0;
  left: 0;
`;
