import { TvGuidePage } from "types";
import rail from "./rail";

export default {
  type: "tvGuidePage",
  channelIds: [
    "4002", // Sky Sports Main Event
    "1303", // Sky Sports Premier League
    "3838", // Sky Sports Football
    "1322", // Sky Sports Golf
    "1302", // Sky Sports Cricket
    "1306", // Sky Sports F1
    "3839", // Sky Sports Arena
    "1314", // Sky Sports News
    "4091", // Sky Sports Mix
    "1354", // Sky Sports Racing
    "5153", // Premier 1 (ROI)
    "1634", // Premier 2 (ROI)
    "3661", // BT SPORT 1
  ],
  rail,
  longPressUpPageId: "tvGuide_music",
  longPressDownPageId: "tvGuide_news",
  title: {
    text: "Sports",
  },
} as TvGuidePage;
