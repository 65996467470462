import { RailsPage } from "types";
import nowAndNext from "./nowAndNext";
import topSportsPicks from "./topSportsPicks";
import trendingToday from "./trendingToday";
import browseBySport from "./browseBySport";
import boxOffice from "./boxOffice";
import footballNews from "./footballNews";
import onDemandTopPicks from "./onDemandTopPicks";
import sportsDocumentaries from "./sportsDocumentaries";
import bestOfYouTube from "./bestOfYouTube";

export default {
  type: "railsPage",
  rails: [
    topSportsPicks,
    nowAndNext,
    trendingToday,
    browseBySport,
    boxOffice,
    footballNews,
    onDemandTopPicks,
    sportsDocumentaries,
    bestOfYouTube,
  ],
  title: {
    logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/75",
    text: "Sports",
  },
} as RailsPage;
