import { Rail } from "types";

export default {
  type: "rail",
  uuid: "a6f12136-c425-4737-b880-2fb09822d741",
  template: "tile-landscape",
  title: "On Now",
  tiles: [
    {
      type: "landscapeTile",
      title: "Line of Duty",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/b7059eb2-baff-4b2d-83fc-ca879b7740fe/16-9/{width}",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc1/408/84",
      hero: {
        mode: "standard",
        title: "Line of Duty",
        synopsis:
          "DS Steve Arnott is transferred to the anti-corruption unit after he refuses to participate in a cover-up that involves an innocent man getting shot in a police encounter.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/b7059eb2-baff-4b2d-83fc-ca879b7740fe/background/{width}",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc1/408/84",
        metadata: "1h   ",
      },
      action: {
        type: "startJourney",
        journeyId: "lineOfDuty",
      },
    },
    {
      type: "landscapeTile",
      title: "What We Do in the Shadows",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/2f30c794-7dff-4d98-b461-85c318b6aca5/16-9/{width}",
      hero: {
        mode: "standard",
        title: "What We Do in the Shadows",
        metadata: "1h  ",
        synopsis:
          "Resurrection. The vampires enlist the services of a local necromancer. Contains some strong language and some violence.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/2f30c794-7dff-4d98-b461-85c318b6aca5/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
    },
    {
      type: "landscapeTile",
      title: "Killing Eve",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/132ba13b-4fe8-424d-85b0-03c609e9cdf2/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Killing Eve",
        metadata: "1h   ",
        synopsis:
          "Nice Face. When a politician is murdered, an MI5 security officer must protect the only witness. Contains some violence and some upsetting scenes.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_bbcthree/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/132ba13b-4fe8-424d-85b0-03c609e9cdf2/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcthree/408/84",
    },
    {
      type: "landscapeTile",
      title: "Arena",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/41a1b11a-a97e-465a-bcba-218a4d45d9bb/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Arena",
        metadata: "1h 45m   ",
        synopsis:
          "I Am Not Your Negro. How the images and reality of black lives in America today are fabricated and enforced. Contains some strong language and upsetting scenes.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_bbcfour/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/41a1b11a-a97e-465a-bcba-218a4d45d9bb/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcfour/408/84",
    },
  ],
} as Rail;
