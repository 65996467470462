import { BrandId, Brand } from "types";
import { videos } from "./videos";

const BRANDS: { [brandId in BrandId]: Brand } = {
  skycinema: {
    brandId: "skycinema",
    backgroundImageUrl: "assets/images/brands/skycinema/background.jpg",
    introVideoUrl: videos.skyCinemaIntro,
    introVideoDurationMs: 1920,
  },
  bbc: {
    brandId: "bbc",
    backgroundImageUrl: "assets/images/brands/bbc/bbc_bg.png",
  },
};

export const getBrand = (brandId: BrandId): Brand => {
  const brand = BRANDS[brandId];
  if (brand) {
    return brand;
  } else {
    throw new Error(`Couldn't find brand with id ${brandId}`);
  }
};
