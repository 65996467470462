import { PageId, Page, PageType, NarrowByType } from "types";
import bbc from "data/pages/bbc";
import bbcone from "data/pages/bbcone";
import bestTv from "data/pages/bestTv";
import home from "data/pages/home";
import kids from "data/pages/kids";
import landlord from "data/pages/landlord";
import lineOfDuty from "data/pages/lineOfDuty";
import movies from "data/pages/movies";
import music from "data/pages/music";
import outOfBlue from "data/pages/outOfBlue";
import recordings from "data/pages/recordings";
import skyCinema from "data/pages/skyCinema";
import sports from "data/pages/sports";
import tv from "data/pages/tv";
import tvGuide_tvShows from "data/pages/tvGuide/tvShows";
import tvGuide_favouriteChannels from "data/pages/tvGuide/favouriteChannels";
import tvGuide_movies from "data/pages/tvGuide/movies";
import tvGuide_music from "data/pages/tvGuide/music";
import tvGuide_sports from "data/pages/tvGuide/sports";
import tvGuide_news from "data/pages/tvGuide/news";
import tvGuide_kids from "data/pages/tvGuide/kids";

const PAGES: { [id in PageId]: Page } = {
  bbc,
  bbcone,
  bestTv,
  home,
  kids,
  landlord,
  lineOfDuty,
  movies,
  music,
  outOfBlue,
  recordings,
  skyCinema,
  sports,
  tv,
  tvGuide_tvShows,
  tvGuide_favouriteChannels,
  tvGuide_movies,
  tvGuide_music,
  tvGuide_sports,
  tvGuide_news,
  tvGuide_kids,
};

export const getPage = <PageT extends PageType>(
  pageId: PageId,
  pageType: PageT
) => {
  const page = PAGES[pageId];
  if (!page) {
    throw new Error(`Couldn't find page ${pageId}`);
  }
  if (page.type !== pageType) {
    throw new Error(`Expected ${pageId} to be of ${pageType}`);
  }
  return page as NarrowByType<Page, PageT>;
};
