import { Rail } from "types";

export default {
  type: "rail",
  uuid: "17db2363-73d4-4b46-ab63-98b6cc37dac2",
  template: "tile-landscape",
  title: "Restart what's on now",
  tiles: [
    {
      type: "landscapeTile",
      title: "Chernobyl",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/3f844a7133af8e4a3230910363f4eb9c/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      hero: {
        mode: "standard",
        title: "Chernobyl",
        synopsis:
          "Watch the most-awarded British series at the 2019 Emmys, with 10 wins. Jared Harris and Emily Watson star in the acclaimed Sky original exploring the truth about the Chernobyl disaster.",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/7995dafa82815d48e7f75f4d16c30a77/herotitle.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/16f80e192a37c6b12fbbb0ab65a4a7bc/hero.jpg",
        metadata: "1h 45m   ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      },
    },
    {
      type: "landscapeTile",
      title: "Live SNF: Liverpool v Man City",
      imageUrl:
        "assets/images/content/liverpool-man-city/liverpool-man-city-tile.png",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysppl/408/84",
      hero: {
        mode: "standard",
        title: "Live SNF: Liverpool v Man City",
        metadata: "2h 45   ",
        synopsis:
          "Pep Guardiola’s Man City welcome Jugen Klopp’s Liverpool to the Etihad Stadium for a Saturday evening clash from the Premier League. With Sky sports Crowds.",
        heroImageUrl:
          "assets/images/content/liverpool-man-city/liverpool-man-city-hero.jpg",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysppl/408/84",
      },
    },
    {
      type: "landscapeTile",
      title: "Newsnight",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/c1eb85a7-a470-4267-87c0-3aae4e89e37c/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Newsnight",
        synopsis:
          "Comprehensive coverage of today's important national and international news stories.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/c1eb85a7-a470-4267-87c0-3aae4e89e37c/background/{width}",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc2/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc2/408/84",
    },
    {
      type: "landscapeTile",
      title: "A Killer Uncaged",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/15216dfd7fa0b9631c6ee4df2dcbdad5/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "A Killer Uncaged",
        metadata: "1h 45m    ",
        synopsis:
          "Three-part docuseries following killer Dale Wayne Sigler, a murderer, as he is released from prison after spending half his life behind bars.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycrime/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/a95554b3c0f99abc8fc718e41ffbcc5a/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/742da89468b09021481bea4a01b4f1a4/herotitle.png",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycrime/408/84",
    },
    {
      type: "landscapeTile",
      title: "Storyville",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/a5900032-4c78-44b8-aeb7-5e0c33a23423/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Storyville",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 1. The inspiring story of men and women in the USA pursuing college degrees while in prison.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_bbcfour/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/a5900032-4c78-44b8-aeb7-5e0c33a23423/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcfour/408/84",
    },
  ],
} as Rail;
