import React, { useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import { TrickPlay } from "./VideoPage.styles";
import { VideoPage as VideoPageType } from "types";
import { useSelector } from "store";
import { MAX_VOLUME } from "reducers/tv";
import gsap from "gsap";

type Props = {
  page: VideoPageType;
  focused: boolean;
};

export const VideoPage = ({ page, focused }: Props) => {
  const { volume, muted } = useSelector(s => s.tv);
  const trickPlayRef = useRef(null);
  useEffect(() => {
    if (page.showTrickPlay) {
      gsap.fromTo(
        trickPlayRef.current,
        { opacity: 1 },
        { opacity: 0, delay: 5 }
      );
    } else {
      gsap.set(trickPlayRef.current, { opacity: 0 });
    }
  }, [page.showTrickPlay]);

  return (
    <>
      <ReactPlayer
        url={page.url}
        playing={true}
        loop={true}
        width="100%"
        height="100%"
        volume={volume / MAX_VOLUME}
        muted={focused || muted}
      />
      <TrickPlay ref={trickPlayRef} />
    </>
  );
};
