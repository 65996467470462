import React from "react";
import { Provider } from "react-redux";
import { SkyProvider } from "components/SkyProvider";
import { AppShell } from "components/AppShell";
import { store } from "store";

export const App = () => {
  return (
    <SkyProvider>
      <Provider store={store}>
        <AppShell />
      </Provider>
    </SkyProvider>
  );
};
