import { epg } from "services/epg";
import {
  call,
  put,
  select,
  takeLatest,
  fork,
  animate,
  throttle,
} from "sagas/effects";
import { programmeHero } from "../../services/heroes";
import {
  selectedDate,
  selectedEvent,
  onNowEvent,
  focusArea,
} from "../../selectors/pages/tvGuide";
import { Hero, LinearEventLookup, PageId, Schedule } from "../../types";
import { getPage } from "data/pages";
import { addDays, isSameDay, max } from "date-fns";
import { FocusArea } from "reducers/browse/pages/tvGuide";
import { now } from "selectors/time";
import { brieflyShowPageTitleInHero } from "sagas/hero";
import { modifyEventLookup, modifySchedule } from "helpers/tvGuideHelpers";

export function* initializeTvGuidePage(pageId: PageId) {
  yield throttle(
    1000,
    [
      "TVGUIDE.NEXT_CHANNEL",
      "TVGUIDE.PREV_CHANNEL",
      "TVGUIDE.NEXT_EVENT",
      "TVGUIDE.PREV_EVENT",
      "TVGUIDE.FOCUS_ON_EPG",
      "TVGUIDE.LOADED_PAGE",
    ],
    loadHero
  );
  yield takeLatest("TVGUIDE.PREV_DAY", prevDay, pageId);
  yield takeLatest("TVGUIDE.NEXT_DAY", nextDay, pageId);
  yield fork(loadOnNow, pageId);
  yield fork(brieflyShowPageTitleInHero);
  yield* loadPage(pageId, new Date());
}

function* loadHero() {
  const area: FocusArea = yield select(focusArea);
  const event =
    area === "channels"
      ? yield select(onNowEvent)
      : area === "grid"
      ? yield select(selectedEvent)
      : undefined;
  const hero: Hero = event
    ? yield call(programmeHero, event.programmeId)
    : { mode: "none" };
  yield put({ type: "TVGUIDE.UPDATE_HERO", hero });
}

function* prevDay(pageId: PageId) {
  const date = yield select(selectedDate),
    dateNow = yield select(now);
  // Don't go back beyond now
  if (dateNow < date) {
    const newDate = max([addDays(date, -1), dateNow]);
    yield put({ type: "TVGUIDE.LOADING_ANOTHER_DAY" });
    yield* loadPage(pageId, newDate);
    yield animate("tvGuidePrevDay");
  }
}

function* nextDay(pageId: PageId) {
  const date = yield select(selectedDate);
  yield put({ type: "TVGUIDE.LOADING_ANOTHER_DAY" });
  yield* loadPage(pageId, addDays(date, 1));
  yield animate("tvGuideNextDay");
}

function* loadPage(pageId: PageId, date: Date) {
  const dateNow: Date = yield select(now);
  const page = getPage(pageId, "tvGuidePage");
  const schedule: Schedule = yield call(epg.schedule, date, page.channelIds);
  yield put({
    type: "TVGUIDE.LOADED_PAGE",
    schedule:
      isSameDay(date, dateNow) && page.linearEventOverrides
        ? modifySchedule(schedule, page.linearEventOverrides, dateNow)
        : schedule,
    date,
  });
}

function* loadOnNow(pageId: PageId) {
  const page = getPage(pageId, "tvGuidePage");
  const eventLookup: LinearEventLookup = yield call(epg.onNow, page.channelIds);
  yield put({
    type: "TVGUIDE.ON_NOW_LOADED",
    eventLookup: page.linearEventOverrides
      ? modifyEventLookup(eventLookup, page.linearEventOverrides)
      : eventLookup,
  });
}
