import { RailsPage } from "types";
import channels from "./channels";
import onNow from "./onNow";
import featured from "./featured";
import mostPopular from "./mostPopular";
import boxSets from "./boxSets";
import drama from "./drama";
import comedy from "./comedy";
import documentaries from "./documentaries";
import entertainment from "./entertainment";
import familyFavourites from "./familyFavourites";
import topPicks from "./topPicks";

export default {
  type: "railsPage",
  brandId: "bbc",
  rails: [
    onNow,
    featured,
    mostPopular,
    boxSets,
    drama,
    comedy,
    documentaries,
    entertainment,
    familyFavourites,
    channels,
    topPicks,
  ],
  title: {
    text: "BBC",
    logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/365/75",
  },
} as RailsPage;
