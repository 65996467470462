import React, { useRef, useEffect } from "react";
import { Text } from "components/Text";
import {
  Container,
  Logo,
  Background,
  StatusBar,
  TextContainer,
} from "./Toast.styles";
import { ToastStatus } from "types";
import { grid } from "constants/dimensions";
import { useAnimations } from "hooks/useAnimations";
import { boolToInt } from "utils/boolToInt";
import gsap from "gsap";
import { Fade } from "components/Fade";
import { useDefaultAnimation } from "hooks/useDefaultAnimation";

type Mode = "header" | "overlay";

type Props = {
  message?: string;
  status?: ToastStatus;
  mode?: Mode;
  quote?: boolean;
  showSeparator?: boolean;
};

const assetUrl = (path: string) => `assets/images/toast/${path}`;

const getOverlayStatusImageUrl = (status: ToastStatus) => {
  switch (status) {
    case "thinking":
      return assetUrl("voice_thinking.png");
    case "success":
      return assetUrl("voice_success.png");
    case "error":
      return assetUrl("voice_error.png");
    default:
  }
  return;
};

const withQuotes = (s: string) => `"${s}"`;

export const Toast = ({
  message,
  status,
  mode,
  quote,
  showSeparator,
}: Props) => {
  status = status || "idle";
  mode = mode || "header";
  const isIdle = status === "idle",
    hidden = mode === "overlay" && isIdle,
    overlayStatusImageUrl = getOverlayStatusImageUrl(status);

  const container = useRef(null),
    background = useRef(null),
    voiceOn = useRef(null),
    voiceListening = useRef(null);

  useDefaultAnimation(container, { opacity: boolToInt(!hidden) });

  useDefaultAnimation(background, { opacity: boolToInt(!!message) });

  const pulseTimeline = useRef(null);
  useEffect(() => {
    pulseTimeline.current = gsap
      .timeline({
        paused: true,
        repeat: -1,
        yoyo: true,
      })
      .fromTo(
        voiceListening.current,
        { opacity: 0 },
        { opacity: 1, duration: 1 }
      );
  }, []);
  useAnimations(
    {
      reset: ({ opacity }) => {
        gsap.set(voiceOn.current, { opacity });
        gsap.set(voiceListening.current, { opacity });
      },
      defaultAnimation: async ({ opacity }) => {
        if (opacity) {
          await gsap.fromTo(
            voiceOn.current,
            { width: "0%", opacity },
            { width: "100%", duration: 0.4 }
          );
          pulseTimeline.current?.play(0);
        } else {
          pulseTimeline.current?.pause();
          await Promise.all([
            gsap.to(voiceOn.current, { opacity }),
            gsap.to(voiceListening.current, { opacity }),
          ]);
        }
      },
    },
    { opacity: boolToInt(!isIdle) }
  );

  return (
    <Container id="toast" ref={container}>
      <Background overlayMode={mode === "overlay"} ref={background} />
      <StatusBar ref={voiceOn} src={assetUrl("voice_on.png")} />
      <StatusBar ref={voiceListening} src={assetUrl("voice_listening.png")} />
      <Fade
        keyBy={overlayStatusImageUrl}
        props={{ imageUrl: overlayStatusImageUrl }}
        render={({ imageUrl }) => <StatusBar src={imageUrl} />}
      />
      <Logo src="./assets/images/sky-logo.png" alt="Sky" />
      <TextContainer>
        {showSeparator && (
          <Text
            size="medium"
            weight="regular"
            style={{
              position: "absolute",
              left: -grid.column.gap,
              opacity: 0.5,
            }}
          >
            |
          </Text>
        )}
        <Text
          size="medium"
          weight="regular"
          style={{ fontStyle: quote ? "italic" : "normal" }}
        >
          {quote ? withQuotes(message) : message}
        </Text>
      </TextContainer>
    </Container>
  );
};
