import { combineReducers } from "redux";
import { initialAppState, appReducer } from "reducers/app";
import { initialToastState, toastReducer } from "reducers/toast";
import { initialTvState, tvReducer } from "reducers/tv";
import { initialSwitcherState, switcherReducer } from "reducers/switcher";
import { initialBrowseState, browseReducer } from "reducers/browse";
import { initialTimeState, timeReducer } from "reducers/time";
import { initialSettingsState, settingsReducer } from "reducers/settings";
import { initialMyListState, myListReducer } from "reducers/myList";
import { initialMiniGuideState, miniGuideReducer } from "reducers/miniGuide";

export const reducer = combineReducers({
  app: appReducer,
  toast: toastReducer,
  tv: tvReducer,
  switcher: switcherReducer,
  browse: browseReducer,
  time: timeReducer,
  settings: settingsReducer,
  myList: myListReducer,
  miniGuide: miniGuideReducer,
});

export type State = Readonly<ReturnType<typeof reducer>>;

export const initialState: State = {
  app: initialAppState,
  toast: initialToastState,
  tv: initialTvState,
  switcher: initialSwitcherState,
  browse: initialBrowseState,
  time: initialTimeState,
  settings: initialSettingsState,
  myList: initialMyListState,
  miniGuide: initialMiniGuideState,
};
