import { TvGuidePage } from "types";
import rail from "./rail";

export default {
  type: "tvGuidePage",
  channelIds: [
    "2512", // MTV MUSIC
    "2506", // MTV HITS
    "2508", // MTV BASE
    "2509", // MTV CLASSIC
    "1350", // Box Hits
    "1802", // The Box
    "1858", // KISS
    "1877", // Magic
    "1853", // Kerrang!
    "1758", // 4MUSIC
    "5708", // B4U Music
  ],
  rail,
  longPressUpPageId: "tvGuide_movies",
  longPressDownPageId: "tvGuide_sports",
  title: {
    text: "Music",
  },
} as TvGuidePage;
