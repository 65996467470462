import { RailsPage } from "types";
import mostRecent from "./mostRecent";
import continueWatching from "./continueWatching";
import entertainment from "./entertainment";
import kids from "./kids";
import movies from "./movies";
import navigation from "./navigation";
import rentals from "./rentals";
import purchases from "./purchases";

export default {
  type: "railsPage",
  rails: [
    mostRecent,
    continueWatching,
    entertainment,
    kids,
    movies,
    navigation,
    rentals,
    purchases,
  ],
  title: {
    text: "My List",
  },
} as RailsPage;
