import styled from "@emotion/styled";
import { screen } from "constants/dimensions";

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("assets/images/miniguide/line-of-duty.png");
  width: ${screen.width}px;
  height: ${screen.height}px;
`;

export const Tick = styled.div`
  position: absolute;
  top: 788px;
  left: 991px;
  width: 32px;
  height: 32px;
  background: top left no-repeat
    url("assets/images/miniguide/icon-plus-white.png");
  opacity: 0;
`;
