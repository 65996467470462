import { State } from "reducers";
import { BrowseTab } from "types";
import { onFirstRail } from "selectors/pages/rails";
import { onHomepage } from "selectors/browse";
import { switcherIsShowing } from "selectors/app";

export const BROWSE_TABS: BrowseTab[] = [
  { title: "Home", pageType: "railsPage", pageId: "home" },
  { title: "TV Shows", pageType: "railsPage", pageId: "tv" },
  { title: "Movies", pageType: "railsPage", pageId: "movies" },
  { title: "Sports", pageType: "railsPage", pageId: "sports" },
  { title: "Kids", pageType: "railsPage", pageId: "kids" },
  { title: "Music", pageType: "imagePage", pageId: "music" },
  { title: "My List", pageType: "railsPage", pageId: "recordings" },
];

export const browseTabs = () => BROWSE_TABS;

export const browseTabIndex = ({ browse: { pageState } }: State) => {
  const index = browseTabs().findIndex(
    ({ pageId }) => pageId === pageState.pageId
  );
  return index === -1 ? undefined : index;
};

export const browseTabsEnabled = (state: State) =>
  state.settings.enableBrowseTabs;

export const showBrowseTabs = (state: State) => {
  if (!browseTabsEnabled(state)) return false;
  if (browseTabIndex(state) === undefined) return false;

  if (onHomepage(state)) {
    return switcherIsShowing(state);
  }

  switch (state.browse.pageState.pageType) {
    case "railsPage":
      return onFirstRail(state);
    default:
      return true;
  }
};

export const browseTabsFocused = (state: State) =>
  state.app.focusArea === "browseTabs";

export const prevBrowseTab = (state: State) => {
  const index = browseTabIndex(state);
  if (index === undefined) return;
  const tabs = browseTabs(),
    newIndex = Math.max(index - 1, 0);
  return tabs[newIndex];
};

export const nextBrowseTab = (state: State) => {
  const index = browseTabIndex(state);
  if (index === undefined) return;
  const tabs = browseTabs(),
    newIndex = Math.min(index + 1, tabs.length - 1);
  return tabs[newIndex];
};
