export const colours = {
  white: "#f1f1f1",
};

export const fonts = {
  regular: "Sky Text Regular",
  medium: "Sky Text Medium",
  light: "Sky Text Light",
};

export const sizes = {
  small: 28,
  medium: 32,
  large: 40,
  huge: 50,
  humungous: 100,
};
