import React from "react";
import hash from "object-hash";
import { Text } from "../Text";
import {
  Container,
  ContentsContainer,
  HeroMask,
  heroImageStyle,
  TitleImage,
  Logo,
  LogoContainer,
  StandardWrapper,
  LargeTitleWrapper,
  HugeTitleWrapper,
  HugeTitleTextWrapper,
  HugeTitleMask,
} from "./Hero.styles";
import { Hero as HeroType } from "types";
import { hero as heroAssetSize } from "constants/assetSizes";
import { imageUrlFromTemplate } from "utils/imageUrlFromTemplate";
import { Fade } from "components/Fade";
import { ImageWithFallback } from "components/ImageWIthFallback";

type Props = {
  hero: HeroType;
  hidden?: boolean;
};

const standardContents = ({
  logoUrl,
  title,
  metadata,
  synopsis,
}: Partial<HeroType>) => (
  <StandardWrapper>
    <LogoContainer>{logoUrl && <Logo src={logoUrl} alt="" />}</LogoContainer>
    {title && (
      <Text size="huge" weight="regular">
        {title}
      </Text>
    )}
    <Text size="medium" weight="regular" style={{ marginTop: 4, height: 42 }}>
      {metadata}
    </Text>
    {synopsis && (
      <Text clamp={3} size="medium" weight="regular">
        {synopsis}
      </Text>
    )}
  </StandardWrapper>
);

const titleArtContents = ({ heroTitleImageUrl, title }: Partial<HeroType>) =>
  heroTitleImageUrl && <TitleImage src={heroTitleImageUrl} alt={title} />;

const largeTitleContents = ({ title, synopsis }: Partial<HeroType>) => (
  <LargeTitleWrapper>
    {title && (
      <Text size="humungous" weight="regular">
        {title}
      </Text>
    )}
    {synopsis && (
      <Text clamp={3} size={44} weight="regular">
        {synopsis}
      </Text>
    )}
  </LargeTitleWrapper>
);

const hugeTitleContents = ({ title }: Partial<HeroType>) => (
  <HugeTitleWrapper>
    <HugeTitleMask />
    <HugeTitleTextWrapper>
      <Text
        size={120}
        weight="light"
        style={{
          display: "flex",
          height: "100%",
          alignItems: "center",
        }}
      >
        {title}
      </Text>
    </HugeTitleTextWrapper>
  </HugeTitleWrapper>
);

const contents = (hero: HeroType) => {
  switch (hero.mode) {
    case "titleArt":
      return titleArtContents(hero);
    case "largeTitle":
      return largeTitleContents(hero);
    case "hugeTitle":
      return hugeTitleContents(hero);
    case "standard":
      return standardContents(hero);
  }
};

const heroMask = ({
  backgroundImageMode,
  mode,
}: HeroType): HeroMask | undefined => {
  if (backgroundImageMode === "partial") {
    return "brand";
  }
  switch (mode) {
    case "titleArt":
    case "hugeTitle":
      return "title";
    case "largeTitle":
    case "standard":
      return "synopsis";
    default:
      return;
  }
};

export const Hero = ({ hero, hidden }: Props) => (
  <Container
    id="hero"
    backgroundImageUrl={hero.backgroundImageMode && hero.backgroundImageUrl}
    maskBackground={hero.backgroundImageMode === "partial"}
    overlay={!!hero.overlay}
  >
    <Fade
      keyBy={hero.heroImageUrl}
      props={{ hero }}
      duration={0.8}
      render={({ hero }) => (
        <ImageWithFallback
          src={imageUrlFromTemplate(hero.heroImageUrl, heroAssetSize)}
          fallbackSrc="assets/images/hero/hero-fallback.png"
          style={heroImageStyle(heroMask(hero))}
        />
      )}
    />
    <Fade
      keyBy={hash(hero)}
      props={{ hero }}
      duration={0.8}
      render={({ hero }) => (
        <ContentsContainer>{contents(hero)}</ContentsContainer>
      )}
    />
  </Container>
);
