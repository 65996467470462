import { put, delay, select } from "sagas/effects";
import { SpecificAction } from "types/actions";
import { getBrand } from "data/brands";
import { initializeTvGuidePage } from "./pages/tvGuide";
import { initializeRailsPage } from "./pages/rails";
import { initializeImagePage } from "./pages/image";

export function* goToPage({
  pageType,
  pageId,
  brandId,
}: SpecificAction<"GO_TO_PAGE">) {
  if (brandId) {
    const brand = getBrand(brandId);
    if (brand.introVideoUrl) {
      yield put({ type: "INTRO.PLAY", videoUrl: brand.introVideoUrl });
      yield delay(brand.introVideoDurationMs - 200);
    }
  }
  yield put({
    type: "BROWSE_FORWARD",
    pageType,
    pageId,
    includeInHistory: true,
  });
}

export function* initializePage() {
  const { pageType, pageId } = yield select(s => s.browse.pageState);
  switch (pageType) {
    case "railsPage":
      yield* initializeRailsPage(pageId);
      break;
    case "imagePage":
      yield* initializeImagePage(pageId);
      break;
    case "tvGuidePage":
      yield* initializeTvGuidePage(pageId);
      break;
  }
}
