import { MutableRefObject } from "react";
import { BasicDeps, useAnimations } from "hooks/useAnimations";
import gsap from "gsap";

export function useDefaultAnimation<Deps extends BasicDeps>(
  ref: MutableRefObject<Element>,
  deps: Deps,
  transition?: gsap.TweenVars
) {
  useAnimations(
    {
      defaultAnimation: async (params, timeline) => {
        if (ref.current) {
          await timeline.to(ref.current, { ...params, ...transition }, 0);
        }
      },
      reset: params => ref.current && gsap.set(ref.current, params),
    },
    deps
  );
}
