import { State } from "reducers";
import { getPage } from "data/pages";
import { MyListItem, Page, PageId, PageType, RailsPage } from "types";
import { updateRail } from "utils/railHelpers";
import { myListItemToTile } from "data/myList";
import compact from "lodash/compact";
import { createSelector } from "reselect";
import { MyListState } from "reducers/myList";

// ----------------------------------------------------------------- //
// THIS IS JUST TEMPORARY! NEED A BIGGER REFACTOR TO TREAT THIS PROPERLY
// ----------------------------------------------------------------- //

const myListTiles = (items: MyListItem[]) =>
  compact(items.map(myListItemToTile));

const addToHomeMyList = (page: RailsPage, items: MyListItem[]) =>
  updateRail(page, "my-list-uuid", rail => {
    const extraTiles = myListTiles(items);
    if (extraTiles.length) {
      const navTile = rail.tiles[0];
      return {
        ...rail,
        tiles: [
          {
            ...navTile,
            hero: {
              ...navTile.hero,
              heroImageUrl: extraTiles[0].hero?.heroImageUrl,
            },
          },
          ...extraTiles,
          ...rail.tiles.slice(1),
        ],
      };
    } else {
      return rail;
    }
  });

const addToMostRecentMyList = (
  page: RailsPage,
  items: MyListItem[]
): RailsPage => {
  const extraTiles = myListTiles(items);
  if (extraTiles.length) {
    return {
      ...updateRail(page, "most-recent-uuid", rail => ({
        ...rail,
        tiles: [...extraTiles, ...rail.tiles],
      })),
    };
  } else {
    return page;
  }
};

const decoratePage = (
  pageId: PageId,
  pageType: PageType,
  page: Page,
  { items }: MyListState
) => {
  switch (pageId) {
    case "home":
      return addToHomeMyList(page as RailsPage, items);
    case "recordings":
      return addToMostRecentMyList(page as RailsPage, items);
    default:
      return page;
  }
};

// ----------------------------------------------------------------- //
// END OF TEMPORARY BIT
// ----------------------------------------------------------------- //

export const currentPage = createSelector(
  (state: State) => state.browse.pageState,
  (state: State) => state.myList,
  (pageState, myList) => {
    const { pageId, pageType } = pageState,
      page = decoratePage(pageId, pageType, getPage(pageId, pageType), myList);
    return { pageState, page };
  }
);
