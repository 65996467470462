import { Rail, Tile } from "types";

const widthFromColumns = (columns: number) =>
  grid.column.width * columns + grid.column.gap * (columns - 1);

const tileBorderRadius = 6;

export const screen = {
  width: 1920,
  height: 1080,
  x: 0,
  y: 0,
};

export const safeArea = {
  horizontal: 80,
  vertical: 50,
};

export const grid = {
  column: {
    width: 49,
    gap: 24,
  },
  firstColumn: {
    get x() {
      return grid.safeArea.width + 16;
    },
  },
  safeArea: {
    width: 80,
    height: 50,
  },
};

export const hero = {
  width: screen.width,
  height: screen.height,
  x: 0,
  y: 0,
  logo: {
    width: 408,
    height: 84,
  },
  titleArt: {
    x: 46,
    y: 83,
    width: 1428,
    height: 803,
  },
  hugeTitle: {
    height: 575,
  },
};

export const toast = {
  textX: grid.firstColumn.x + widthFromColumns(2) + grid.column.gap,
};

export const browseTabs = {
  width: 1148,
  height: 52,
  marginTop: 55,
  get marginSide() {
    return (screen.width - this.width) / 2;
  },
  tab: { width: 164 },
  focus: {
    get width() {
      return browseTabs.tab.width;
    },
    height: 72,
  },
};

export const clock = {
  x: 1745,
  y: 66,
};

export const switcherTile = {
  width: widthFromColumns(8),
  get outerWidth() {
    return this.width + grid.column.gap;
  },
  get height() {
    return this.width / (16 / 9);
  },
  borderRadius: tileBorderRadius,
  get focusedHeight() {
    const scale = (this.width + 2 * grid.column.gap) / this.width;
    return scale * this.height;
  },
  get extraFocusedHeight() {
    return this.focusedHeight - this.height;
  },
};

export const switcher = {
  switcherMode: {
    Y: 575,
  },
  browseMode: {
    Y: 149,
  },
  innerWidth: widthFromColumns(24),
};

export const switcherTileLogo = {
  width: 336,
  height: 69,
};

export const onNowTile = {
  width: switcherTile.width,
  height: switcherTile.height,
  borderRadius: tileBorderRadius,
};

export const browse = {
  idle: {
    x: 0,
    y: 426,
  },
  focused: {
    x: 0,
    y: 0,
  },
};

export const railsPage = {
  yLines: {
    "tile-promo": 421,
    "tile-category": 600,
    default: 575,
  },
};

export const rail = {
  height: (template: Rail["template"]) => {
    switch (template) {
      case "tile-landscape":
        return tiles.landscapeTile.height;
      case "tile-promo":
        return tiles.promoTile.height;
      case "tile-cover":
        return tiles.coverTile.height;
      case "tile-now-next":
        return tiles.nowAndNextTile.height;
      case "tile-category":
        return tiles.categoryTile.height;
      case "tile-square":
        return tiles.squareTile.height;
    }
  },
  extraFocusedHeight: (template: Rail["template"]) => {
    switch (template) {
      case "tile-landscape":
      case "tile-promo":
        return 38;
      case "tile-cover":
      case "tile-now-next":
      case "tile-square":
        return 58;
      case "tile-category":
        return 18;
    }
  },
  focusedHeight: function(template: Rail["template"]) {
    return this.height(template) + this.extraFocusedHeight(template);
  },
  innerWidth: widthFromColumns(24),
  titleOffset: function(
    allowForFocusState: boolean,
    template: Rail["template"]
  ) {
    return (
      -47 - (allowForFocusState ? this.extraFocusedHeight(template) / 2 : 0)
    );
  },
  marginTop: (showTitle: boolean) => (showTitle ? 84 : 40),
};

export const tvGuide = {
  yLines: {
    get withoutRail() {
      return 575 - tvGuide.titles.height;
    },
    withRail: 575 - rail.marginTop(true),
  },
  leftMargin: 92,
  item: {
    paddingLeft: 25,
    height: 68,
    marginBottom: 4,
    get outerHeight() {
      return this.height + this.marginBottom;
    },
  },
  titles: {
    height: 49,
  },
  channelNames: {
    width: 410,
  },
  grid: {
    slotWidth: 434,
    itemMarginRight: 4,
    width: screen.width,
  },
  railContainer: {
    get height() {
      return rail.marginTop(true) + rail.height("tile-landscape");
    },
    marginBottom: 56,
  },
  overflowAllowanceForFocusState: {
    x: 8,
    y: 6,
  },
};

export const tiles: {
  [key in Tile["type"]]: {
    width: number;
    height?: number;
    borderRadius: number;
    [attr: string]: number;
  };
} = {
  titleTile: {
    width: widthFromColumns(4),
    borderRadius: tileBorderRadius,
  },

  landscapeTile: {
    width: widthFromColumns(6),
    get height() {
      return this.width / (16 / 9);
    },
    borderRadius: tileBorderRadius,
  },

  coverTile: {
    width: widthFromColumns(4),
    get height() {
      return this.width / (3 / 4);
    },
    borderRadius: tileBorderRadius,
  },

  categoryTile: {
    width: widthFromColumns(6),
    height: 154,
    borderRadius: tileBorderRadius,
  },

  promoTile: {
    width: widthFromColumns(12),
    get height() {
      return this.width / (16 / 9);
    },
    borderRadius: tileBorderRadius,
  },

  nowAndNextTile: {
    width: widthFromColumns(6),
    height: 357,
    borderRadius: tileBorderRadius,
    nowHeight: 234,
    get nextHeight() {
      return this.height - this.nowHeight;
    },
  },

  squareTile: {
    width: widthFromColumns(4),
    get height() {
      return this.width;
    },
    borderRadius: tileBorderRadius,
  },
};
