import { differenceInDays, format, startOfDay } from "date-fns";

export const formatTime = (date: Date | number) =>
  format(date, "h:mma").toLowerCase();

export const formatDay = (date: Date, today: Date) => {
  switch (differenceInDays(startOfDay(date), startOfDay(today))) {
    case 0:
      return "Today";
    case 1:
      return "Tomorrow";
    case -1:
      return "Yesterday";
    default:
      return format(date, "E d");
  }
};
