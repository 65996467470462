import React, { useRef, memo } from "react";
import styled from "@emotion/styled";
import { railsPage } from "constants/dimensions";
import { Rail } from "components/Rail";
import { RailsPage as RailsPageType, Rail as RailType } from "types";
import { transitions } from "constants/transitions";
import { pageOffsetY } from "./helpers";
import { useDefaultAnimation } from "hooks/useDefaultAnimation";
import { grid } from "constants/dimensions";
import { slice } from "lodash";

const Container = styled.div`
  position: relative;
  left: ${grid.firstColumn.x}px;
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;
`;

type Props = {
  page: RailsPageType;
  index?: number;
  railIndices?: { [index: number]: number };
  focused?: boolean;
};

const getYLine = (template?: RailType["template"]) =>
  railsPage.yLines[template] || railsPage.yLines.default;

export const RailsPage = memo(
  ({ page, index, railIndices, focused }: Props) => {
    index = index || 0;
    railIndices = railIndices || {};
    const stackElement = useRef(null),
      focusedRail = page.rails[index];

    useDefaultAnimation(
      stackElement,
      {
        y:
          getYLine(focusedRail?.template) -
          pageOffsetY(page, index, railIndices),
      },
      transitions.changeRail
    );

    // Trim offscreen rails
    const minVisibleRails = 3;
    const trimmedRails = slice(page.rails, 0, index + minVisibleRails);

    return (
      <Container>
        <Stack ref={stackElement}>
          {trimmedRails.map((rail, railIndex) => (
            <Rail
              rail={rail}
              railIndex={railIndex}
              focusedRailIndex={index}
              focusedTileIndex={railIndices[railIndex] || 0}
              key={rail.uuid}
              showFocusedGlassFrame={!!focused}
            />
          ))}
        </Stack>
      </Container>
    );
  }
);
