import { Rail } from "types";

export default {
  type: "rail",
  uuid: "111c8797-497c-4293-a036-100b565d6093",
  template: "tile-category",
  tiles: [
    {
      type: "categoryTile",
      title: "Settings",
      hero: {
        mode: "titleArt",
        heroImageUrl: "assets/images/settings/settings.jpg",
      },
    },
    {
      type: "categoryTile",
      title: "Help",
      hero: {
        mode: "titleArt",
        heroImageUrl: "assets/images/settings/help.jpg",
      },
    },
    {
      type: "categoryTile",
      title: "My Account",
      hero: {
        mode: "titleArt",
        heroImageUrl: "assets/images/settings/myaccount.jpg",
      },
    },
    {
      type: "categoryTile",
      title: "Your Sky Bill",
      hero: {
        mode: "titleArt",
        heroImageUrl: "assets/images/settings/skybill.jpg",
      },
    },
    {
      type: "categoryTile",
      title: "Payments",
      hero: {
        mode: "titleArt",
        heroImageUrl: "assets/images/settings/payments.jpg",
      },
    },
    {
      type: "categoryTile",
      title: "Forgotten PIN?",
      hero: {
        mode: "titleArt",
        heroImageUrl: "assets/images/settings/forgotten-pin.jpg",
      },
    },
    {
      type: "categoryTile",
      title: "Parental Settings",
      hero: {
        mode: "titleArt",
        heroImageUrl: "assets/images/settings/parental-settings.jpg",
      },
    },
  ],
} as Rail;
