import { ChannelId as ChanID } from "data/channels";
import { fonts, sizes } from "../constants/type";

// Utils
export type NarrowByType<U, T> = U extends { type: T } ? U : never;
export type Thenable = {
  then: (callback: () => void) => void;
};

// Brands

export const brandIds = ["bbc", "skycinema"] as const;
export type BrandId = typeof brandIds[number];

export type Brand = {
  brandId: BrandId;
  backgroundImageUrl: string;
  introVideoUrl?: string;
  introVideoDurationMs?: number;
};

// App-level

export type ToastStatus =
  | "idle"
  | "listening"
  | "thinking"
  | "success"
  | "error";

export type FocusArea = "switcher" | "browse" | "browseTabs";

export const heroModes = [
  "none",
  "standard",
  "titleArt",
  "largeTitle",
  "hugeTitle",
] as const;
export type HeroMode = typeof heroModes[number];
export type Hero = {
  mode: HeroMode;
  heroImageUrl?: string;
  logoUrl?: string;
  metadata?: string;
  synopsis?: string;
  title?: string;
  heroTitleImageUrl?: string;
  backgroundImageUrl?: string;
  backgroundImageMode?: "full" | "partial";
  overlay?: boolean;
};

export type PageTitle = {
  text?: string;
  logoUrl?: string;
};

export type NavStep =
  | "LEFT"
  | "RIGHT"
  | "UP"
  | "DOWN"
  | "LONG_LEFT"
  | "LONG_RIGHT"
  | "LONG_UP"
  | "LONG_DOWN"
  | "SELECT"
  | "BACK"
  | "HOME"
  | "ADD";

// Tiles and Rails

export type UserAction =
  | { type: "goToPage"; pageType: PageType; pageId: PageId; brandId?: BrandId }
  | { type: "startJourney"; journeyId: JourneyId; pageIndex?: number };

export type TitleTile = {
  type: "titleTile";
  title?: string;
  actionText?: string;
  hero?: Hero;
  action?: UserAction;
};

export type LandscapeTile = {
  type: "landscapeTile";
  title?: string;
  imageUrl?: string;
  providerImageUrl?: string;
  action?: UserAction;
  hero?: Hero;
};

export type CoverTile = {
  type: "coverTile";
  title: string;
  imageUrl: string;
  hero?: Hero;
  action?: UserAction;
};

export type PromoTile = {
  type: "promoTile";
  title: string;
  imageUrl: string;
  hero?: Hero;
  action?: UserAction;
};

export type CategoryTile = {
  type: "categoryTile";
  title: string;
  hero?: Hero;
  action?: UserAction;
};

export type NowAndNextTile = {
  type: "nowAndNextTile";
  providerImageUrl: string;
  nowTitle: string;
  nowStart: number;
  nextTitle: string;
  nextStart: number;
  progressPercent: number;
  hero?: Hero;
  action?: UserAction;
};

export type SquareTile = {
  type: "squareTile";
  title?: string;
  imageUrl: string;
  hero?: Hero;
  action?: UserAction;
};

export type Tile =
  | TitleTile
  | LandscapeTile
  | CoverTile
  | CategoryTile
  | PromoTile
  | NowAndNextTile
  | SquareTile;

// TV Guide
export type ChannelId =
  | ChanID
  | "placeholder-channel-1"
  | "placeholder-channel-2"
  | "placeholder-channel-3"
  | "placeholder-channel-4"
  | "placeholder-channel-5"
  | "placeholder-channel-6";

export type Channel = {
  title: string;
  id: ChannelId;
  channelNumber: string;
};

export type ChannelCategory = {
  title: string;
  channels: Channel[];
};

export type LinearEvent = {
  startMs: number;
  endMs: number;
  programmeId: string;
  title: string;
};

export type LinearEventLookup = Partial<
  {
    [channelId in ChannelId]: LinearEvent | undefined;
  }
>;
export type PartialLinearEventLookup = Partial<
  { [channelId in ChannelId]: Partial<LinearEvent> }
>;

export type ChannelSchedule = {
  channel: Channel;
  events: LinearEvent[];
};

export type Schedule = {
  channels: ChannelSchedule[];
};

// Switcher

export type RecentTile = {
  type: "recentTile";
  imageUrl: string;
  tag: string;
  title?: string;
  providerImageUrl?: string;
  progress?: number;
  hero: Partial<Hero>;
  journeyId?: JourneyId;
  action?: UserAction;
};

export type OnNowTile = {
  type: "onNowTile";
  journeyId: JourneyId;
  pageIndex: number;
  imageUrl?: string;
  providerImageUrl?: string;
};

export type NextTile = {
  type: "nextTile";
  title: string;
  providerImageUrl: string;
  imageUrl: string;
  tag: string;
  progress?: number;
  hero: Partial<Hero>;
};

export type SwitcherTile = RecentTile | OnNowTile | NextTile;

export type Switcher = {
  type: "switcher";
  uuid: string;
  tiles: SwitcherTile;
};

export type Rail = {
  type: "rail";
  uuid: string;
  template:
    | "tile-landscape"
    | "tile-cover"
    | "tile-category"
    | "tile-promo"
    | "tile-now-next"
    | "tile-square";
  tiles: Tile[];
  title?: string;
  brandId?: BrandId;
};

// Pages

export const railsPageIds = [
  "bbc",
  "bbcone",
  "home",
  "kids",
  "movies",
  "recordings",
  "skyCinema",
  "sports",
  "tv",
] as const;
export type RailsPageId = typeof railsPageIds[number];

export const gridPageIds = ["bestTv"] as const;
export type GridPageId = typeof gridPageIds[number];

export const imagePageIds = [
  "music",
  "landlord",
  "lineOfDuty",
  "music",
  "outOfBlue",
] as const;
export type ImagePageId = typeof imagePageIds[number];

export const tvGuidePageIds = [
  "tvGuide_tvShows",
  "tvGuide_favouriteChannels",
  "tvGuide_movies",
  "tvGuide_music",
  "tvGuide_sports",
  "tvGuide_news",
  "tvGuide_kids",
] as const;
export type TvGuidePageId = typeof tvGuidePageIds[number];

export const pageIds = [
  ...railsPageIds,
  ...gridPageIds,
  ...imagePageIds,
  ...tvGuidePageIds,
];
export type PageId = typeof pageIds[number];

export type RailsPage = {
  type: "railsPage";
  rails: Rail[];
  brandId?: BrandId;
  title?: PageTitle;
};

export type GridPage = {
  type: "gridPage";
  template: "tile-landscape" | "tile-cover";
  tiles: Tile[];
  gridTitle: string;
};

export type VideoPage = {
  type: "videoPage";
  url: string;
  showTrickPlay?: boolean;
};

export type ImagePage = {
  type: "imagePage";
  url: string;
  action?: UserAction;
  title?: PageTitle;
};

export type TvGuidePage = {
  type: "tvGuidePage";
  channelIds: ChannelId[];
  rail: Rail;
  longPressUpPageId?: PageId;
  longPressDownPageId?: PageId;
  title?: PageTitle;
  linearEventOverrides?: PartialLinearEventLookup;
};

export type Page = RailsPage | GridPage | VideoPage | ImagePage | TvGuidePage;
export type PageType = Page["type"];

// Journeys

export const journeyIds = [
  "capture",
  "coast",
  "landlord",
  "lineOfDuty",
  "netflix",
  "outOfBlue",
  "greatestShowman",
  "skynews",
] as const;
export type JourneyId = typeof journeyIds[number];

export type Journey = {
  id: JourneyId;
  title: string;
  intro?: {
    durationMs: number;
    url: string;
  };
  pages: Page[];
};

// Browse Tabs
export type BrowseTab = {
  title: string;
  pageType: PageType;
  pageId: PageId;
};

// Types associated with fonts
export type FontSize = keyof typeof sizes | number;
export type FontWeight = keyof typeof fonts;

// My List
export type MyListItem = {
  itemType: "series"; // | "app", etc.
  itemId: string;
};
