import { UserAction } from "types";
import { Action } from "types/actions";

export const userActionToReduxAction = (
  userAction: UserAction | undefined
): Action | undefined => {
  if (!userAction) return;

  switch (userAction.type) {
    case "goToPage": {
      const { pageType, pageId, brandId } = userAction;
      return { type: "GO_TO_PAGE", pageType, pageId, brandId };
    }
    case "startJourney": {
      const { journeyId, pageIndex } = userAction;
      return { type: "JOURNEY.START", journeyId, pageIndex };
    }
  }
};
