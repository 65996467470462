import { RailsPage } from "types";
import apps from "./apps";
import becauseYouWatchedTalesOfNy from "./becauseYouWatchedTalesOfNy";
import channels from "./channels";
import editorial from "./editorial";
import lastNightsTv from "./lastNightsTv";
import recordings from "./recordings";
import skyChannels from "./skyChannels";
import skyOriginals from "./skyOriginals";
import skyStore from "./skyStore";
import topPicks from "./topPicks";
import trending from "./trending";
import ultraHd from "./ultraHd";
import unmissableTvSeries from "./unmissableTvSeries";

export default {
  type: "railsPage",
  rails: [
    topPicks,
    lastNightsTv,
    trending,
    becauseYouWatchedTalesOfNy,
    skyOriginals,
    unmissableTvSeries,
    ultraHd,
    channels,
    skyChannels,
    editorial,
    apps,
    recordings,
    skyStore,
  ],
  title: {
    text: "TV Shows",
  },
} as RailsPage;
