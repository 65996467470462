import React, { useEffect, useRef } from "react";
import { Container, Tick } from "./MiniGuide.styles";
import { Fade } from "components/Fade";
import { gsap } from "gsap";

type Props = { visible: boolean };

export const MiniGuide = ({ visible }: Props) => {
  const tick = useRef(null);

  useEffect(() => {
    if (visible && tick.current) {
      gsap.fromTo(
        tick.current,
        { scale: 0, opacity: 0 },
        { scale: 1, opacity: 1, delay: 0.8, duration: 0.5, ease: "back.out" }
      );
    }
  }, [visible]);

  return (
    <Fade
      visible={visible}
      render={() => (
        <Container>
          <Tick ref={tick} />
        </Container>
      )}
    />
  );
};
